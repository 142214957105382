/*! nouislider - 15.4.0 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  direction: ltr;
  position: relative;
  margin: 3rem 0;
  // margin-top: 4.5rem;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
*/
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
/* Offset direction
*/
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}
.noUi-vertical .noUi-origin {
  top: -100%;
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Styling;
*/
.noUi-target {
  height: 2px;
  background: $athens-gray;
}
.noUi-connects {}
.noUi-connect {
  background: $charade;
}
/* Handles and cursors;
*/
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-connects,
.noUi-connect,
.noUi-handle {
  cursor: pointer;
}
.noUi-handle {
  outline: none;
  width: 1.4rem;
  height: 1.4rem;
  top: -0.6rem;
  right: -0.7rem;
  position: absolute;
  background: $white;
  border-radius: 100%;
  border: 2px solid $charade;
}
.noUi-active {
  border-color: $spun-pearl;
}
.noUi-tooltip {
  top: -2.5rem;
  display: none;
  font-size: 1.4rem;
  position: absolute;
  white-space: nowrap;
  transform: translateX(-40%);
  .noUi-active &,
  .noUi-handle:hover & {
    display: block;
  }
}
/* Disabled state;
*/
[disabled].noUi-target {
  opacity: 0.4;
  cursor: default;
}
