html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: Gilroy;
  background-color: var(--page-background);
  color: var(--text-color);
  text-align: unset;
  // @include nonselect();
  min-width: 320px;
}

p { line-height: 2.4rem; }

@mixin background-logo {
  // background-image: url("../static/images/logo.png");
  // background-attachment: local;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.fadeIn { @include fadeIn(); }
.fadeOut {
  opacity: 0;
  @include transition(700ms);
}

h1, h2, h3, h4, h5, h6 { font-weight: 600; }
h1 { font-size: 4rem; line-height: 4.8rem; }
h2 { font-size: 3.2rem; line-height: 4rem; }
h3 { font-size: 2.4rem; line-height: 3.2rem; }
h4 { font-size: 2.2rem; line-height: 3.0rem; }
h5 { font-size: 1.8rem; line-height: 2.6rem; }
h6 { font-size: 1.6rem; line-height: 1.6rem; }

$anchor: 'a, a:hover, a:visited, a:link, a:focus, a:active';
#{$anchor} {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

[class*="container"] {
  --bs-gutter-x: 1.5rem;
  @include media-breakpoint-down("sm") {
    --bs-gutter-x: 2rem;
  }
}

.row {
  margin-right: calc(var(--bs-gutter-x) * -1);
  margin-left: calc(var(--bs-gutter-x) * -1);
  & > * {
    padding-right: calc(var(--bs-gutter-x) * 1);
    padding-left: calc(var(--bs-gutter-x) * 1);
  }
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-auto {
  height: auto !important;
}

// .ms-auto { margin-inline-start: auto; }
// .me-auto { margin-inline-end: auto; }

.pointer {
  cursor: pointer;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}
.bg-success {
  background-color: var(--success-color) !important;
}
.bg-warning {
  background-color: var(--warning-back) !important;
}
.bg-danger {
  background-color: var(--danger-color) !important;
}
//=======kourosh
.img-responsive{
  max-width: 100%;
  height: auto;
}
.col-lg-pr-0{
  @include media-breakpoint-up("lg") {
    padding-right: 0 !important;
  }
}
.col-lg-pl-0{
  @include media-breakpoint-up("lg") {
    padding-left: 0;
  }
}
.col-pr-sm-0{
  @include media-breakpoint-down("sm") {
    padding-right: 0;
  }
}
.col-pl-sm-0{
  @include media-breakpoint-down("sm") {
    padding-left: 0;
  }
}
