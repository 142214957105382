.ticket {
  width: 22rem;
  margin: auto;
  color: $white;
  display: flex;
  padding: 1.5rem;
  position: relative;
  border-radius: 2rem;
  align-items: center;
  background-color: var(--primary-color);
  box-shadow: 0px 4px 14px rgb(#000, 0.1);
  &-wrapper {
    overflow: hidden;
    max-height: 34rem;
  }
  &-price {
    padding: 1rem;
    font-size: 2rem;
    font-weight: bold;
    margin-inline-end: 1rem;
    border-inline-end: 1px solid rgb(#fff, 0.1);
  }
  &-area {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.8rem;
  }
  &-seat {
    opacity: 0.6;
    font-size: 1.2rem;
  }
  &-remove {
    width: 1.8rem;
    cursor: pointer;
    font-size: 1.6rem;
    position: absolute;
    text-align: center;
    line-height: 1.8rem;
    top: 6px; right: 6px;
    border-radius: 1.8rem;
    background-color: rgb($mine-shaft, 0.2);
  }
  &::before,
  &::after {
    content: '';
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 2.8rem;
    position: absolute; top: 50%;
    background-color: var(--background);
  }
  &::before {
    left: 0;
    transform: translate(-50%, -50%);
  }
  &::after {
    right: 0;
    transform: translate(50%, -50%);
  }
  @include media-breakpoint-down('lg') {
    width: 18rem;
    flex: 0 0 auto;
    margin: 0 0.25rem;
    &-wrapper > * > * {
      display: flex;
      overflow: auto;
      flex-flow: row nowrap;
      margin: 0 -0.25rem;
    }
    &-price { font-size: 1.4rem; }
  }
}
