/** Imported from Bootstrap 4.6.0 **/

// Responsive Alignment
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .text#{$infix}-justify  { text-align: justify !important; }
    .text#{$infix}-nowrap   { white-space: nowrap !important; }
    .text#{$infix}-wrap     { white-space: normal !important; }
    .break#{$infix}-word    { word-break: break-word !important; }
    .break#{$infix}-all     { word-break: break-all !important; }
    .text#{$infix}-truncate { @include text-truncate(); }
    .text#{$infix}-left     { text-align: left !important; }
    .text#{$infix}-right    { text-align: right !important; }
    .text#{$infix}-start    { text-align: start !important; }
    .text#{$infix}-end      { text-align: end !important; }
    .text#{$infix}-center   { text-align: center !important; }
  }
}

// Transformation
.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }
.first-capitalize {
  display: block;
  &::first-letter {
    text-transform: uppercase !important;
  }
}

// Sizes
.text-caption {
  font-size: 1.2rem;
  line-height: 1.4rem;
}

// Colors
.text-primary { color: var(--primary-color) !important; }
.text-secondary { color: var(--secondary-color) !important; }
.text-success { color: var(--success-color) !important; }
.text-danger { color: var(--danger-color) !important; }
.text-warning { color: var(--warning-color) !important; }
.text-info { color: var(--info-color) !important; }
