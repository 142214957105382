$si-bg-item: $alabaster;
$si-bg-color: $white;
$si-border-radius: 8px;
$si-border-color: $iron;

.select-input {
    width: 100%;
    font-size: 1.4rem;
    min-height: 4.8rem;
    line-height: 3.2rem;
    padding: 0.5rem 1.5rem;
    cursor: text;
    display: flex;
    font-weight: 500;
    position: relative;
    align-items: center;
    flex-flow: row nowrap;
    color: var(--text-color);
    @include transition(0.2s);
    &.multi {
        flex-wrap: wrap;
    }
    background-color: $si-bg-color;
    border-radius: $si-border-radius;
    border: 1px solid $si-border-color;
    &.is-empty {
    }
    &:hover {
    }
    &.focused {
        border-color: $wild-sand;
        background-color: $wild-sand;
    }
    &.disabled {
        opacity: 0.4;
        cursor: default;
    }
    .input-field {
        border: 0;
        flex: 1 1 0;
        max-width: 100%;
        padding: 2px 1rem;
        box-sizing: border-box;
        @include text-truncate();
        background-color: transparent;
        &:focus,
        &:hover {
            outline: 0;
        }
        @include placeholder-color(rgb($mine-shaft, 0.4));
    }
    .input-icon {
        font-size: 1.8rem;
    }
    .input-item {
        order: 6;
        display: flex;
        flex: 0 1 auto;
        padding: 0 1rem;
        max-width: 100%;
        flex-wrap: nowrap;
        font-size: 1.4rem;
        font-weight: bold;
        border-radius: 8px;
        align-items: center;
        align-items: center;
        box-sizing: border-box;
        background-clip: padding-box;
        border: 2px solid transparent;
        background-color: $si-bg-item;
    }
    .input-item-check {
        color: $white;
        width: 1.8rem;
        height: 1.8rem;
        font-size: 1rem;
        line-height: 1.7rem;
        text-align: center;
        border-radius: 5px;
        font-weight: bolder;
        display: inline-block;
        vertical-align: bottom;
        margin-inline-end: 1rem;
        border: 2px solid var(--text-color);
        &.icon-tick {
            background-color: var(--text-color);
        }
    }
    .input-item-remove {
        padding: 2px;
        cursor: pointer;
        font-size: 2rem;
        color: $astronaut;
        border-radius: 4px;
        font-weight: bolder;
        line-height: 0.8rem;
        margin-inline-end: 0.5rem;
        // font-family: Arial, Helvetica, sans-serif;
    }
    .input-items-count {
        color: $astronaut;
        font-weight: 600;
    }
    .input-clear {
        flex: 0 1 auto;
        cursor: pointer;
        margin: 0 0.5rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: $astronaut;
        // font-family: Arial, Helvetica, sans-serif;
    }
    .input-arrow {
        font-size: 1rem;
        font-weight: bolder;
        &-up {
            transform: rotate(-90deg);
        }
        &-down {
            transform: rotate(90deg);
        }
        transition: all 500ms ease;
        &:hover {
            cursor: pointer;
        }
    }

    .input-dropdown {
        width: 100%;
        z-index: 1000;
        margin-top: 4px;
        overflow: hidden;
        padding: 0.5rem 0;
        line-height: 2rem;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: $si-bg-color;
        border-radius: $si-border-radius;
        box-shadow: 0px 10px 24px 0px rgb(#000, 0.1);
    }
    .input-message,
    .input-button {
        padding: 1rem;
        cursor: default;
        text-align: center;
        @include text-truncate();
        &:not(:last-child) {
            border-bottom: 1px solid $si-border-color;
        }
    }
    .input-button {
        cursor: pointer;
        @include transition(0.2s);
        &:hover {
            background-color: $si-bg-item;
        }
    }
    .select-list {
        margin: 0;
        overflow-y: auto;
        list-style: none;
        padding: 0 0.5rem;
        max-height: 20rem;
        position: relative;
        scroll-behavior: smooth;
    }
    .select-option {
        padding: 1rem;
        cursor: pointer;
        position: relative;
        border-radius: 5px;
        @include text-truncate();
        @include transition(0.2s);
        background-color: $si-bg-color;
        // &.selected { background-color: $athens-gray; }
        &.item-navigator {
            background-color: $wild-sand;
        }
        &.separate:not(:first-child)::before {
            content: "";
            width: 90%;
            height: 1px;
            margin: auto;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgb(#000, 0.1);
        }
    }
}
