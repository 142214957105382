.carousel {
  display: flex;
  &-wrapper {
    overflow: hidden;
    position: relative;
  }
  &-slide {
    flex: 0 0 auto;
    @include nonselect();
  }
}

.mirror {
  &, > * > * {
    transform: rotateY(180deg);
  }
}
