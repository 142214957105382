/*!
 * Bootstrap Grid v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

$include-column-box-sizing: true !default;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "~bootstrap/scss/mixins/lists";
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/container";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/mixins/utilities";

@import "~bootstrap/scss/vendor/rfs";

@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";

@import "~bootstrap/scss/utilities";
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
  (
    "display",
    "order",
    "flex",
    "flex-direction",
    "flex-grow",
    "flex-shrink",
    "flex-wrap",
    "justify-content",
    "align-items",
    "align-content",
    "align-self",
    "margin",
    "margin-x",
    "margin-y",
    "margin-top",
    // "margin-end",
    "margin-bottom",
    // "margin-start",
    "negative-margin",
    "negative-margin-x",
    "negative-margin-y",
    "negative-margin-top",
    "negative-margin-end",
    "negative-margin-bottom",
    "negative-margin-start",
    "padding",
    "padding-x",
    "padding-y",
    "padding-top",
    // "padding-end",
    "padding-bottom",
    // "padding-start",
  )
);

html[lang="fa"] {
  @each $n in map-keys($spacers) {
    .ms-#{$n} { margin-right: #{map-get($spacers, $n)} !important; }
    .me-#{$n} { margin-left: #{map-get($spacers, $n)} !important; }
    .ps-#{$n} { padding-right: #{map-get($spacers, $n)} !important; }
    .pe-#{$n} { padding-left: #{map-get($spacers, $n)} !important; }
  }
}

html:not([lang="fa"]) {
  @each $n in map-keys($spacers) {
    .ms-#{$n} { margin-left: #{map-get($spacers, $n)} !important; }
    .me-#{$n} { margin-right: #{map-get($spacers, $n)} !important; }
    .ps-#{$n} { padding-left: #{map-get($spacers, $n)} !important; }
    .pe-#{$n} { padding-right: #{map-get($spacers, $n)} !important; }
  }
}

@import "~bootstrap/scss/utilities/api";
