
  .paymentSuccess{
      margin-top: 1.5rem;
      margin-bottom: 5rem;
      padding-bottom: 10rem;
  }


.payment-dashed-b{
    margin-top: 3rem;
    margin-bottom: 5rem;
}
.payment-success{
    h1,p{
    color: $astronaut;
    }
    h1{
        font-size: 3rem;
        font-weight: 700;
    }
    p{
       font-size: 1.6rem;
    }
    .TrackCode{
        font-family: Gilroy;
        font-size: 2.4rem;
        font-weight: 700;
        display: inline-block;
        width: 100%;
        padding-top: 3.7rem ;
        padding-bottom: 3.7rem;
        color: $white;
        background-color: $astronaut;
        border-radius: 10px;
    }
}
.success-msg{
    // span{
    //     color: #00D32F;
    //     font-size: 1.6rem;
    //     font-weight: 600;
    //     display: inline-block;
    //     margin-left: 2rem;
    // }
    .texticon{
        &-paymentsuccess{
            color: #00D32F;
            font-size: 1.6rem;
            font-weight: 600;
            display: inline-block;
        }
        .icon{
            transform: translateY(0px);
            padding-right: 2rem;
            font-size: 2.2rem;
        }
    }
}

.ticketDetail {
    .price{
        span,strong{
            font-size: 1.6rem;
        }
        span{
            color: $pale-sky;
        }
        strong{
            color:$astronaut;
            display: inline-block;
            font-weight: 700!important;
        }
    }
}
// .TicketPrice  , .TicketDate{
// flex:1;
// }
.TicketPrice span ,.Ticketdate span{
    color: rgba(40, 82, 111, 0.4) ;
}

.TicketPrice strong , .Ticketdate strong {
color: $astronaut;
}

//========== media
@media screen and (max-width: 768px){
    .TrackCode{
        padding-top: 2.5rem !important;
        padding-bottom: 2.5rem !important;
    }
    .payment-success{
        h1{
            font-size: 2rem!important;
        }
    }
}
.btn{
    &.dl-ticket{
        width: 100%;
    }
    &.view-ticket{
        width: 100%;
        font-weight:500
    }
}
