.checkbox,
.radio {
  margin: 0;
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  cursor: pointer;
  position: relative;
  margin-inline-end: 1rem;
  @include transition(100ms);
  background-clip: content-box;
  border-radius: $border-radius;
  background-color: transparent;
  border: 2px solid var(--primary-color);
}

.checkbox::after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 40%;
  height: 80%;
  content: '';
  margin: auto;
  display: block;
  position: absolute;
  border-right: 2px solid;
  border-bottom: 2px solid;
  transform: rotate(40deg);
  border-color: var(--background);
}

.radio {
  padding: 3px;
  border-radius: 100%;
}

input:focus~.checkbox,
input:focus~.radio {
  border-color: var(--primary-color);
}

input:checked~.checkbox,
input:checked~.radio {
  background-color: var(--primary-color);
}

input:not(:checked):disabled~.radio {
  // background-color: var(--checkbox-disable);
  background-clip: padding-box;
}

.items {
  list-style: none;
  padding: 0;
  margin: 0;
}

.shrink {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}
