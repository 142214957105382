.concert-filter{
  padding: 3rem 2rem;
  background-color: $white;
  border-radius: 1rem;
  h3{
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.05);
  }
  form{
    label{
      font-weight: 700;
      text-transform: capitalize;
    }
    input,select{
      &:focus{
        outline: none;
      }
    }
    select{
      border:none;
      background-color: #F5F5F5;
      text-transform: capitalize;
      border-radius: .8rem;
      opacity: 0.8;
      &:hover , &:focus{
        opacity: 1;
      }
      &.order{
      opacity: 0.2;
      border:none;
      &:hover{
        opacity: 1;
      }
      }
    }
    .filter-select{
      position: relative;
      &:before{
        content:"\e915";
        font-size: 1.7rem;
        font-family: 'ticlix';
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%);
        display: inline-block;
      }
    }
  }
}
.concert-mobile-filter{
  box-shadow: 0px 14px 24px rgba(220, 81, 54, 0.15);
  border-radius: 10px;
  overflow: hidden;
  position: fixed;
  bottom: 13rem;
  left: 50%;
  z-index: 4;
  transform: translate(-50%,50%);
  .texticon{
    white-space: nowrap;
    display: block;
    color: $white;
    background-color: $flame-pea;
    padding:1.5rem 1.2rem;
    &:first-child{
      border-right: 1.5px solid rgba(255, 255, 255, 0.2);;
    }
  }
}
.btn{
  &-white{
    color: $white !important;
    border-color: $white !important;
  }
}
.filterSlideRight{
  left:   0!important;
  z-index: 6;
}
.slideUpFilter{
  top: 0 !important;
  transition: all 2s !important;
}
.filter-m-price .noUi-handle{
border-color:var(--text-active) ;
border-width: .4rem;
}
