.cart{
    margin-bottom: 5rem;
}
.CartTicket{
    background:$white;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.05);
    border:1px solid transparent;
    border-radius: 10px;
        .upperSection{
            padding:1.6rem 2.4rem 0rem 2.4rem;
            @include media-breakpoint-down("sm"){
              padding: 1.6rem 1rem 0rem 1rem;
            }
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            &>div{
                flex: 1;
            }
            color: $astronaut;
        }
        h2{
            font-size: 2.4rem;
            color:$astronaut;
           }
        .DeleteTicket{
            transform: translateY(20px);
            .icon{
                @include media-breakpoint-down("sm"){
                    font-size: 1.8rem;
                }
            }
        }
        .downSection{
            padding:0 2rem 1.6rem;
            @include media-breakpoint-down("sm"){
                padding: 1.6rem 1rem 0rem 1rem;
              }
        }
        .price{
            font-size: 2.4rem;
            font-weight: 600;
        }
        .seatleft{
            color: rgba($mine-shaft,0.6);
        }
        .TimeleftBuy{
            color: $astronaut;
            position: relative;
            .timeleft-tooltip{
                background: $white;
                min-width: 370px;
                box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
                color: rgba($mine-shaft,0.6) !important;
                position: absolute;
                top: -150%;
                left: 52%;
                transform: translate(-50%,-50%);
                opacity: 0;
                visibility: hidden;
                transition:all .4s;
            }
            &-txt{
                border-radius: 16px;
                background-color: rgba($astronaut,.05);
            }
            &:hover{
                .timeleft-tooltip{
                    opacity: 1;
                    visibility: visible;
                    top: -200%;
                }
            }
            &-active{
                border-radius: 16px;
                color:$flame-pea !important;
                background-color: rgba($flame-pea,.05) !important;
            }
        }
       .changeSeat{
           color: $astronaut;
           text-decoration-line: underline;
       }
       .price-section{
           border-bottom: 1px solid rgba(0, 0, 0, 0.05);
       }

    //================new style===========
    .chosenseats{
        position: relative;
        flex: auto 0 0 !important;
        min-width: 35px;
            .icon{
                transform: translateX(2px);
            }
            .seatstooltip{
               content: "";
               color: $white;
               font-size: 1.4rem;
               padding:1rem 1.4rem;
               background-color: var(--text-color);
               border-radius: 1rem;
               position: absolute;
               top: 10%;
               left: 50%;
               transform: translate(-50%,-50%);
               transition: all .4s;
               opacity: 0;
               visibility: hidden;
               white-space: nowrap;
               &::before{
                   content: "";
                   width: 15px;
                   height: 15px;
                   position: absolute;
                   top: 95%;
                   left: 50%;
                   transform: translate(-50%,-50%) rotate(45deg);
                   background-color: inherit;
                   border-radius: 2px;
               }
            }
        &:hover{
            .seatstooltip{
                opacity: 1;
                visibility: visible;
                top: -5%;
            }
        }
    }
    .seatno{
        font-size: 1rem;
    }
    .carousel-wrapper{
        overflow:auto;
        overflow-y: visible !important;
        overflow-x: hidden;
        // transform: translateX(-2rem);
        .chosenseats:first-child{
          margin-left: 3rem;
        }
    }

    .icon-right-chevron{
     position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 1rem;
    border-radius: 50%;
    border:1px solid rgba($mine-shaft, .4);
    box-shadow: 0 0 15px 15px #f9f9f9;
    color: var(--text-color);
    display: inline-block;
    opacity: 0.3;
    font-size: 1rem;
    opacity: 1;
    background-color: $white;
    cursor: pointer;
    }
    .prev{
     transform: rotate(180deg) translateY(53%);
    left:-10px;
    }
    .next{
        right: -10px;
    }
 }
 //=====================
.coupon{
    input
    &:focus,&:hover{
        outline: none;
        border:none;
        background-color: transparent;
    }
}
.coupon-success{
    color:#00D32F;
}
.priceDetail{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: $astronaut;
}
.totalPrice , .Tprice{
    color:$astronaut;
    font-size: 2.4rem;
}
.paymentMethods{
    img{
      display: block;
      border-radius: 5px;
      margin-bottom: 1rem;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.16);
    }
    display: flex;
    color: #232121;
    font-size: 1.4rem;
    justify-content: space-around;
}
//\====== coupon form
.cart-coupon{
    position: relative;
     label{
        display: inline;
        font-size: 1.2rem;
        background:transparent;
        color: #23212194;
        position: absolute;
        pointer-events: none;
        top: 50%;
        left: 18%;
        transform: translate(-50%,-50%);
        padding: 0.5rem;
        transition:background-color 10ms , top 500ms ,left 500ms;
     }
     .removeCoupon{
        position: absolute;
        top: 50%;
        right: 1.5rem;
        width: 0.8rem;
        transform: translateY(-50%);
        font-size: 1rem;
    }
    input{
        border: 1.5px solid #EAEAEB;
        background-color: transparent;
        border-radius: 8px;
        padding: 1.8rem 1rem;
        width: 100%;
        background: #EAEAEB;
        border: 1.5px solid #EAEAEB;
         &:focus , &:valid{
          outline:none;
          background: transparent;
           &+label{
             top:0;
             left:15%;
           background: $white;
           }
         }
    }
}
.ActiveCoupon{
    border-color:#00D32F !important;
    background: $white !important;
}
.error-coupon{
border-color:$flame-pea !important;
background: $white !important;
}
.coupon-successmsg , .coupon-failuremsg{
color:#00D32F;
font-size:1.2rem;
display: inline-block;
    .icon{
        padding-right: 8px;
        font-size: 14px;
    }
}
.coupon-failuremsg{
    color:$flame-pea;
}
.Cart-ApplyCoupon{
    color:$white !important;
    font-weight: 600;
    background-color:$flame-pea ;
    border-radius: 4px;
    padding:1.8rem 2rem;
}
.payment-gateways{
    flex: 0 0 auto;
    cursor: pointer;
    text-align: center;
    img{
        border: 1px solid transparent;
        transition: all .3s;
    }
    &:hover, &.active {
        img {
            border-color: $flame-pea;
            box-shadow: 0px 4px 11px rgba(220, 81, 54, 0.16);
        }
    }
}
//========= MEDIA
@media screen and (max-width: 768px){
    .concertArtist{
        font-size: 1.6rem;
        font-weight: 600;
    }
    .CartTicketDetail{
        font-size: 1.4rem;
    }
}
.emptycart{
    max-width: 100%;
    // width: 80vw;
    height: 70vh;
    h3{
        color:$astronaut;
    }
}
