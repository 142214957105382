.mr-15{
    margin-right: 1.5rem;
}
.fs-14{
  font-size: 1.4rem;
}
.fs-13{
    font-size: 1.3rem;
  }
.fs-12{
    font-size: 1.2rem;
}
// ==========
.icons-white{
    color: rgba($color: #ffffff, $alpha: 0.8);
}
.icons-grey{
    color: #23212170;
}
.icons-lightBlue{
    color: #28526F70;
}
.icons-fs12{
    font-size: 1.2rem !important;
}
.icons-fs14{
    font-size: 1.4rem !important;
}
//===========================
.texticon{
    display: inline-block;
    text-transform: capitalize;
    font-family: 'Gilroy';
    .icon{
        padding-right: 0.8rem;
        display: inline-block;
        vertical-align: -webkit-baseline-middle;
    }
}
.concert-country{
    display: block;
    font-size: 1.2rem;
    padding-left: 2.5rem;
    color: $nobel;
    text-transform: capitalize;
    line-height: 1rem;
}
