.table-wrapper {
  width: 100%;
  overflow-y: auto;
}

.table {
  width: 100%;
  font-size: 1.4rem;
  line-height: 2rem;
  border-spacing: 0;
  table-layout: auto;
  border-collapse: collapse;
  tr {
    @include transition(100ms);
    border-bottom: 1px solid var(--border-color);
  }
  th, td {
    padding: 1rem 1.5rem;
  }
  tbody tr.active,
  tbody tr:not(.empty):hover {
    background-color: var(--table-hover);
  }
  tbody tr:not(:first-child).empty {
    display: none;
  }
  .icon {
    display: block;
    font-size: 3rem;
    line-height: inherit;
  }
  select:focus { outline: none; }
}
.limit { max-width: 10rem; }

/* Reports table */
.bordered {
  th {
    color: var(--text-inverse);
    border: 1px solid var(--text-inverse);
    background-color: var(--secondary-color);
  }
  td {
    border: 1px solid var(--border-color);
  }
  tr { border: none; }
}
.zebra {
  tr:nth-child(odd) {
    background-color: var(--background);
  }
  tr:nth-child(even) {
    background-color: var(--page-background);
  }
}

/* Policies table */
.paginated {
  thead tr, & {
    border-bottom: 2px solid var(--secondary-color);
  }
  th {
    color: var(--secondary-color);
  }
}
