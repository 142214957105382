@font-face {
    font-family: "ticlix";
    src: url("ticlix.eot?pwdd2l");
    src: url("ticlix.eot?pwdd2l#iefix") format("embedded-opentype"),
        url("ticlix.ttf?pwdd2l") format("truetype"),
        url("ticlix.woff?pwdd2l") format("woff"),
        url("ticlix.svg?pwdd2l#ticlix") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "ticlix" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-x {
    background-image: url("/static/images/icons/icon-x.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 16px;
    background-size: cover;
    display: inline-flex;
}

footer .icon-x {
    filter: brightness(0) saturate(100%) invert(44%) sepia(2%) saturate(2024%)
        hue-rotate(2deg) brightness(100%) contrast(78%);
}

.icon-facebook:before {
    content: "\e903";
}
.icon-telegram:before {
    content: "\e904";
}
.icon-twitter:before {
    content: "\e905";
}
.icon-youtube:before {
    content: "\e906";
}
.icon-instagram:before {
    content: "\e907";
}
.icon-diverse:before {
    content: "\e93b";
}
.icon-female:before {
    content: "\e93c";
}
.icon-male:before {
    content: "\e93d";
}
.icon-chair:before {
    content: "\e938";
}
.icon-wheel-chair:before {
    content: "\e939";
}
.icon-edit:before {
    content: "\e934";
}
.icon-circle-arrow:before {
    content: "\e936";
}
.icon-right-chevron:before {
    content: "\e93a";
}
.icon-chevron:before {
    content: "\e935";
}
.icon-menu:before {
    content: "\e900";
}
.icon-play:before {
    content: "\e901";
}
.icon-seat:before {
    content: "\e926";
}
.icon-untitled:before {
    content: "\e927";
}
.icon-sort:before {
    content: "\e928";
}
.icon-history:before {
    content: "\e929";
}
.icon-zoom-out:before {
    content: "\e92a";
}
.icon-zoom-in:before {
    content: "\e92b";
}
.icon-genre:before {
    content: "\e92c";
}
.icon-map:before {
    content: "\e92d";
}
.icon-dashboard:before {
    content: "\e92e";
}
.icon-clock:before {
    content: "\e92f";
}
.icon-close:before {
    content: "\e930";
}
.icon-concert:before {
    content: "\e931";
}
.icon-today-events:before {
    content: "\e932";
}
.icon-bookmark:before {
    content: "\e909";
}
.icon-location:before {
    content: "\e923";
}
.icon-time-circle:before {
    content: "\e91b";
}
.icon-calendar:before {
    content: "\e90a";
}
.icon-call:before {
    content: "\e90b";
}
.icon-chat:before {
    content: "\e90c";
}
.icon-photo:before {
    content: "\e937";
}
.icon-close-square:before {
    content: "\e90d";
}
.icon-danger-triangle:before {
    content: "\e90e";
}
.icon-ticket:before {
    content: "\e91a";
}
.icon-bag:before {
    content: "\e908";
}
.icon-discount:before {
    content: "\e90f";
}
.icon-logout:before {
    content: "\e91c";
}
.icon-download:before {
    content: "\e910";
}
.icon-filter:before {
    content: "\e911";
}
.icon-heart:before {
    content: "\e91e";
}
.icon-info-square:before {
    content: "\e922";
}
.icon-phone:before {
    content: "\e933";
}
.icon-Message:before {
    content: "\e925";
}
.icon-notification:before {
    content: "\e912";
}
.icon-setting:before {
    content: "\e916";
}
.icon-star:before {
    content: "\e918";
}
.icon-tick-square:before {
    content: "\e919";
}
.icon-tick:before {
    content: "\e93e";
}
.icon-home:before {
    content: "\e921";
}
.icon-video:before {
    content: "\e91d";
}
.icon-search:before {
    content: "\e915";
}
.icon-profile:before {
    content: "\e914";
}
.icon-add-user:before {
    content: "\e902";
}
.icon-wallet:before {
    content: "\e920";
}
.icon-password:before {
    content: "\e913";
}
.icon-lock:before {
    content: "\e924";
}
.icon-show:before {
    content: "\e917";
}
.icon-hide:before {
    content: "\e91f";
}
