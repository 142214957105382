.breadcrumsWrapper{
padding:0;
}
.breadcrumbs{
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    margin-top: 3.2rem;
    ul{
        padding:3.4rem 3.2rem;
        margin: 0;
        list-style-type: none;
         font-size: 1.4rem;
         color: rgba(255, 255, 255, 0.4);
         li{
             float: left;
             text-transform: capitalize;
             &:after{
                 content: "/";
                 padding:0.5rem 0.8rem;
             }
             &:last-child{
                 &:after{
                     content: "";
                 }
             }
             &.active-bread{
                 color:$white
             }

         }

    }
}