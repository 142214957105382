.PaymentBackHolder {
  border-radius: 1rem;
  background-color: $white;
  box-shadow: #000000, 25%;
}

.PaymentBackSec {
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
  direction: ltr;
  &_back {
    margin-left: 20px;
  }
  .paymentBack {
    &_Link {
      font-weight: 300;
      font-family: Roboto;
      font-size: 1.6rem;
      display: inline-block;
    }
  }
}
