.userinvoice {
    background-color: white;
    padding: 4.2rem 3.6rem;
}
table.invoices-table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    overflow: hidden;
    thead tr.i-tb {
        border-bottom: 1px solid rgba($mine-shaft, 0.2);
    }
    tr.i-tb {
        border-bottom: 1px solid rgba($mine-shaft, 0.05);
        margin: 1rem 0;
        // td:first-child{
        //   color:$astronaut;
        //   background-color: rgba($astronaut,0.1);
        //   margin:1rem;
        //   padding:0.8rem;
        //   border-radius: 1rem;
        //   display: inline-block;
        //   width: 110%;
        // }
    }
    tbody.i-tb > tr > td {
        padding: 1.6rem;
        text-align: center;
        white-space: nowrap;
    }
    thead.i-tb > tr > th {
        padding: 1.6rem;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: capitalize;
    }
    tbody.i-tb > tr {
        &:hover {
            background-color: rgba($mine-shaft, 0.03);
        }
    }
    .icon-circle-arrow {
        font-size: 2.5rem;
    }
    .seeDetail {
        display: inline-block;
        transform: rotate(90deg) !important;
        pointer-events: none;
        transition: all 0.5s;
        &[arrow-up] {
            transform: rotate(-90deg) !important;
        }
    }
}
@media screen and (max-width: 600px) {
    .invoices-table {
        border: 0;
        thead.i-tb {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        tr.i-tb {
            display: block;
            margin-bottom: 2rem;
            border-radius: 1rem;
            box-shadow: 0 13px 13px rgba($mine-shaft, 0.05);
        }
        tbody.i-tb > tr > td {
            border-bottom: 1px solid rgba($mine-shaft, 0.04);
            display: flex;
            justify-content: space-between;
            font-size: 0.8em;
            text-align: right;
            &:last-child {
                border: none;
            }
            // &:first-child{font-weight: 700; background-color: rgba($mine-shaft, 0.03);}
            .btn {
                @include media-breakpoint-down("sm") {
                    padding: 0.5rem 1rem;
                    font-size: 1.4rem;
                    border-width: 1px;
                }
            }
        }
        tbody.i-tb > tr > td:first-child {
            margin: 0;
            justify-content: flex-start;
            border-radius: 1rem 1rem 0 0;
            padding: 1.6rem;
            color: $astronaut;
            background-color: rgba($astronaut, 0.1);
        }
        tbody.i-tb > tr > td::before {
            content: attr(data-label);
            font-weight: 600;
        }
    }
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        width: auto !important;
    }
}
.totalInvoice {
    border-radius: 1rem;
    background-color: var(--text-active);
    color: $white;
    @include media-breakpoint-up("lg") {
        width: 30%;
    }
}
.single-invoice-tb {
    tbody td:first-child {
        display: flex;
        justify-content: space-between;
    }
}
.InvoiceModal {
    height: 100vh;
    width: 100%;
    background-color: rgba($mine-shaft, 0.5);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.5s;
}
.invoice-Mcontent {
    border-radius: 1rem;
    background-color: $white;
    width: 30%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 40%;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    transition: all 0.35s;
    @include media-breakpoint-down("md") {
        width: 90%;
    }
    &[showmodal] {
        top: 50%;
        opacity: 1;
        visibility: visible;
    }
}
.back-invoice {
    color: rgba($mine-shaft, 0.5);
}
.invoice-detail {
    max-height: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.35s;
}
.showinvoice-dt {
    max-height: auto;
    height: auto;
    visibility: visible;
    transition: all 0.35s;
    overflow-x: auto;
    display: table-row;
}
.invoice-dt-tb {
    // width: 90% !important;
    box-shadow: 0 3px 13px rgba($nobel, 0.5);
    border-radius: 1rem !important;
    transition: all 0.5s;
}
.invoice-dt-tb tbody tr:nth-child(2n + 1) {
    background-color: rgba($nobel, 0.05);
}
.invoice-dt-tb tbody tr:last-child {
    border: none;
}
.table > :not(caption) > * > * {
    padding: 1rem;
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: auto;
    @include media-breakpoint-up("sm") {
        width: 56vw;
    }
    // @include media-breakpoint-up('lg'){
    //   width: 55vw;
    // }
    // @include media-breakpoint-up('xxl'){
    //   width: 48vw;
    // }
    @media (min-width: 1800px) {
        width: 44vw;
    }
}
.table .icon-close {
    font-size: 1rem;
    background-color: $white;
    filter: drop-shadow(0 0 13px #10101020);
    padding: 0.4rem 0.8rem;
    border-radius: 50%;
    display: flex;
    margin: auto;
}
.invoice-dt-tb td,
.invoice-dt-tb th {
    white-space: nowrap;
}
