.auth-wrapper {
    min-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    color: var(--text-inverse);
    background-color: var(--auth-background);
    background: url("/static/images/auth-pattern.png") no-repeat right center;
}

.auth-brand {
}

.auth-title {
    font-size: 1.4rem;
    line-height: 1.6rem;
    a {
        text-decoration: underline;
    }
}

.auth-comment {
    font-size: 1.2rem;
    line-height: 1.6rem;
    // box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
    // padding: 2rem 1.6rem;
    // position: relative;
    // border-radius: 5px;
    // &::after{
    //   content: "";
    //   display: inline-block;
    //   background-color: $white;
    //   width: 2rem;
    //   height: 2rem;
    //   position: absolute;
    //   top: 95%;
    //   left: 50%;
    //   transform: translate(-50%,-50%) rotate(45deg);
    // }
}

[class*="auth-form"] {
    width: 51rem;
    @include media-breakpoint-only("xs") {
        width: 35rem;
    }
}
.auth-form {
    &-top {
        position: relative;
        color: var(--text-disable);
        img {
            padding: 0;
            width: 100%;
            position: absolute;
        }
        &.first img {
            transform: scale(-1, 1);
        }
        *:not(img) {
            z-index: 0;
            cursor: pointer;
            font-size: 1.5rem;
            text-align: center;
            padding: 2rem 1.5rem;
            color: $white;
            &.active {
                color: var(--text-color);
            }
            @include media-breakpoint-only("xs") {
                padding: 1.2rem 1.5rem;
            }
        }
    }
    &-top ~ &-main {
        border-radius: 0;
    }
    &-main {
        padding-bottom: 0rem;
        background-color: $white;
        color: var(--text-disable);
        border-radius: 25px 25px 0 0;
    }
    &-main,
    &-after {
        padding: 4rem 8rem;
        @include media-breakpoint-only("xs") {
            padding: 3rem 1.5rem;
            padding-bottom: 1rem;
        }
    }
}

.auth-back {
    margin: 6rem;
    text-align: center;
    &-icon {
        width: 5rem;
        height: 5rem;
        margin: auto;
        display: block;
        border-radius: 100%;
        margin-bottom: 2rem;
        background-color: $white;
        background-clip: content-box;
        border: 0.4rem solid #{$white}2;
        position: relative;
        &:before {
            content: "\e921";
            font-family: "ticlix";
            font-size: 1.7rem;
            position: absolute;
            color: #232323;
            top: 48%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: inline-block;
        }
    }
}

.auth-counter {
    cursor: default !important;
    color: var(--primary-color);
    background-color: transparent;
    // border-color: var(--primary-color);
    border-color: rgba($flame-pea, 0.3);
    @include media-breakpoint-down("sm") {
        border-color: rgba($flame-pea, 0.1);
    }
}

.auth-register {
    color: $white;
    border-color: $white !important;
}

.styles_react-code-input__CRulA {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
        font-size: 2.4rem !important;
        font-family: Gilroy !important;
        color: var(--text-color) !important;
        border-radius: $border-radius !important;
        border: 2px solid var(--border-color) !important;
        &:focus {
            border-color: var(--primary-color) !important;
        }
        &:not([value=""]) {
            border-color: transparent !important;
            color: var(--text-inverse) !important;
            background-color: var(--primary-color) !important;
        }
    }
}
.wl-back {
    font-weight: 300;
    font-size: 2rem;
    color: #232121;
    line-height: 2.3rem;
}
.sign-tc {
    font-size: 1.4rem;
    color: #232121;
    opacity: 0.6;
}
.regtobuy {
    font-weight: 700;
    @include media-breakpoint-down("sm") {
        font-size: 1.8rem;
    }
}
.fweight-700 {
    font-weight: 700;
}
.fweight-300 {
    font-weight: 300;
}
.fweight-500 {
    font-weight: 500;
}
.login-dha {
    font-weight: 600;
    @include media-breakpoint-up("lg") {
        font-weight: 300;
    }
}
//==========
.reg-area-code {
    padding: 1.25rem 1rem;
    background-color: #eeeeee;
    border-radius: 1rem;
    input {
        background-color: transparent;
        border-width: 0;
        outline: none;
        width: 40px;
        text-align: center;
    }
    @include media-breakpoint-down("sm") {
        transform: translateY(2px);
    }
}
.reg-area-code.has-error {
    border: 1px solid;
    border-color: var(--danger-color);
}
.reg-area-code input:focus::placeholder {
    color: transparent;
}
.reg-area-code input:focus::-webkit-placeholder {
    color: transparent;
}
.checkUremail {
    color: var(--text-color);
    line-height: 1.6rem;
    margin-bottom: 1px;
}
.editinputreg {
    color: $astronaut !important;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        color: $astronaut !important;
    }
}
.entervercode {
    font-size: 2rem;
    color: var(--text-color);
    &-sub {
        font-size: 1.4rem;
    }
}
.styles_react-code-input__CRulA {
    input {
        width: 64px !important;
        height: 64px !important;
    }
}
//===========Congratulation menu ==========
.shortcutMenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        float: left;
        cursor: pointer;
        @include media-breakpoint-up("lg") {
            margin: 0 1.2rem;
        }
        .icon {
            color: $white;
            padding: 1.6rem;
            border: 5px solid transparent;
            border-radius: 50%;
            transition: all 0.3s;
        }
        &:hover {
            .icon {
                background-color: $white;
                color: var(--text-color);
                border-color: rgba($mine-shaft, 0.1);
            }
        }
    }
}
.complete-prof {
    h5,
    p {
        font-weight: 300;
        color: var(--text-color) !important;
    }
    input {
        padding-left: 4rem;
    }
    .icon {
        position: absolute;
        top: 15px;
        left: 12px;
    }
    .form-control-ns {
        & + label {
            left: 10%;
        }
        &:focus {
            .icon {
                color: var(--text-color) !important;
            }
        }
        &:focus,
        &:valid,
        &.dirty {
            & + label {
                top: -7px;
            }
        }
    }
}
.have-account {
    width: 100%;
    @include media-breakpoint-up("lg") {
        width: 30vw;
    }
    .btn {
        &-login {
            color: $white !important;
            border-color: $white !important;
        }
    }
}
