@use "sass:color";

:root {
  --auth-background: #{$tuna};
  --page-background: #{$tuna};
  --footer-background: #{$shark};
  --background: #{$white};
  --border-color: #{$mercury};
  --aside-color: #{$bismark};
  --footer-color: #{$pickled-bluewood};

  --text-color: #{$mine-shaft};
  --text-active: #{$flame-pea};
  --text-bold: #{$mine-shaft};
  --text-weak: #{$mine-shaft};
  --text-disable: #{$nobel};
  --text-inverse: #{$white};

  --primary-color: #{$flame-pea};
  --secondary-color: #{rgb($mine-shaft, 0.4)};
  --success-color: #{$caribbean-green};
  --info-color: #{$bismark};
  --warning-color: #{$pizazz};
  --danger-color: #{$mandy};
  --disable-color: #{$gallery};

  --warning-back: #{$goldenrod};

  --alert-success: #{color.adjust($caribbean-green, $lightness: $alert-bg-level, $alpha: $alert-bg-alpha)};
  --alert-info: #{color.adjust($bismark, $lightness: $alert-bg-level, $alpha: $alert-bg-alpha)};
  --alert-warning: #{color.adjust($pizazz, $lightness: $alert-bg-level, $alpha: $alert-bg-alpha)};
  --alert-danger: #{color.adjust($mandy, $lightness: $alert-bg-level, $alpha: $alert-bg-alpha)};

  --input-border: #{$silver};
  --checkbox-disable: #{$geyser};
}
