.changepassword{
  background-color: white;
  padding: 4.2rem 3.6rem ;
  @include media-breakpoint-down("sm"){
    padding:1.6rem 0 4rem 0;
    background-color: transparent;
    h3,p{
      color: $white;
    }
    .lastchange{
      opacity: .4;
    }
    .icon-show , .icon-hide{
      color: rgba($white,.6);
    }
  }
  border-radius: 1rem;
  text-transform: capitalize;
  .alertbox{
    padding:1.6rem 2rem;
    border:1px solid rgba($mine-shaft,.2);
    border-radius: 1rem;
    @include media-breakpoint-down("sm"){
      background-color: rgba($white,.1) !important;
      padding:1.6rem .2rem;
      p{
        color: rgba($white,.4);
        b{
          color: rgba($flame-pea,1);
        }
      }
    }
  }
  .changepassbtn{
    @include media-breakpoint-down("sm"){
     margin-top: 10rem;
    a{
      width: 100%;
    }
    }
  }
}
.form-group-newstyle-changepass{
  position: relative;
  min-height: 56px;
  margin-bottom:2rem;
  &::before , &::after{
    font-family: "ticlix";
    display: inline-block;
    font-size: 1.6rem;
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    z-index: 2;
  }
  &.has-error  {
    border-color: var(--text-active) !important;
    margin-bottom: 5rem;
    input{border-color: var(--text-active) !important;}
  }
  &:before{
    content: "\e924";
    color: #B6B6B6;
    left:12px;
  }
  .icon{
    position: absolute;
    top: 50%;
   transform: translateY(-50%);
  right: 20px;
  }
  input{
    width: 100%;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    padding: 1.8rem 2rem 1.8rem 3.5rem;
    background-color:#EEEEEE;
    border:1px solid transparent;
    @include media-breakpoint-down("sm"){
      background-color: rgba($white,.1);
      color:rgba($white,.8);
      &.af-new{
        border-color: #B6B6B6 !important;
        color: var(--text-color) !important;
      }
    }
    &:focus , &:valid{
      outline:none;
      background: transparent;
      border-color:$nobel;
      @include media-breakpoint-down("sm"){
        border-color: rgba($white,0.1);
      }
       &+label{
         top:-3px;
         left:8rem;
       background: $white;
       @include media-breakpoint-down("sm"){
        background-color: var(--page-background);
        color:rgba($white,.4);
        &.af-new{
          background-color: $white !important;
          color: rgba($nobel,.6) !important;
        }
        }
       &.passagain{
        left: 8.5rem;
        @include media-breakpoint-down("sm"){
          left:28%
          }
        }
        .af-new{
          @include media-breakpoint-down("sm"){
            background-color:#EEEEEE;
            color:inherit;
           }
        }
      }
    }
     &:focus{
       border-color: $nobel;
       @include media-breakpoint-down("sm"){
        border-color: rgba($white,0.1);
      }
     }
     &.has-error{
      border-color: var(--text-active) !important;
    }
  }
  label{
    position: absolute;
    top: 50%;
    left: 9rem;
    transform: translate(-50%,-50%);
    color: #B6B6B6;
    line-height: 1rem;
    padding: 0 2px;
    pointer-events: none;
    @include media-breakpoint-down("sm"){
      left:9rem
    }
    &.passagain{
      left: 10rem;
      @include media-breakpoint-down("sm"){
        left:10rem
      }
    }
  }
  .feedback{
    position: absolute;
    top:110%;
    color: var(--text-active);
    margin-bottom: 2rem;
  }
}

.changepass-bread-sm{
      span{
        color: rgba($white,.6) !important;
      }
}
.changepass-bread-mh{
  min-height: 40px;
}
.notsame{
  font-size: 1.4rem;
  color: var(--text-active);
}
.prof-showpass{
  left: 90%!important;
  position: absolute !important;
  top: 18px !important;
}
