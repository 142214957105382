.arrow {
  --color: #{$mine-shaft};
  --width: 2.8rem;
  padding: 0;
  opacity: 0.4;
  display: block;
  font-size: 1.2rem;
  position: relative;
  width: var(--width);
  height: var(--width);
  color: var(--color);
  border-radius: 100%;
  background: transparent;
  border: 2px solid var(--color);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  @include transition(100ms);
  &::after {
    margin: auto;
    content: '\e93a';
    position: absolute;
    font-family: ticlix;
    top: 50%; left: 50%;
    transform: translate(-60%, -50%) rotate(180deg);
  }
  &.inverse { transform: rotate(180deg); }
  &:focus { outline: 0; }
  &:disabled { opacity: 0.2; }

}
