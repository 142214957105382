@import 'variables';

html[lang="fa"] {
  body { direction: rtl;  font-family: IRANSans ;}
  .scrollbars {
    > :first-child {
      margin-right: 0 !important;
      margin-left: -15px !important;
    }
    > :last-child {
      left: 2px !important;
      right: unset !important;
    }
  }
  .leaflet-tooltip { direction: rtl; }
  /* =============================== */
  .text-right-fa { text-align: right !important; }
  .concert-pagination .pagination-prev{
    padding-right:0
  }
  .texticon{
    font-family: IRANSans;
    .icon{
      padding-right:0 ;
      padding-left:.8rem;
      transform: translateY(-5px) !important;
    }
  }
  .ticketBox .icon-right-chevron,
  .carouselItem .icon-right-chevron,
  .calendar-prev, aside .icon-right-chevron {
    transform: rotate(180deg) translateY(5px) !important;
  }
  .ticketBox  {
    .texticon:last-child{
      .icon{
        transform: translateY(3px) !important;
      }
    }
  }
  .dashboardMenu a{display: flex;align-items: center;}
  .PaymentSteps *{font-family: IRANSans;}
  .concert-country {
    padding-left: 0;
    padding-right: 2.5rem;
  }
  button.arrow:first-child {
    order:2 !important;
  }
  .EventLoc div:first-child,
  .fortoday .seatLeft, .HomeCarouselItem,
  .carouselItem , .TicketCarousel ,
  .fortoday .priceHolder{
    direction: rtl;
  }
  .calendar-next , .dp-next{
    transform: rotate(0deg);
  }
  .fortoday .price , .ticketShapeWrapper {
    text-align: right !important;
  }
  .lr-label-placeholder{ left: auto;right:1.5rem}
  .form-group-newstyle {
    input{
      padding-left:unset;
      padding-right: 4rem;
    }
    .lr-label-placeholder{
      right: 3.5rem;
    }
  }
  .form-control-ns{
    &:focus , &:valid{
      left: auto;
      right: 1.5rem;
    }
  }
  .form-group-newstyle-changepass input {
    padding: 1.8rem 3.5rem;
    text-align: left;
    direction: ltr;
  }
  .form-group-newstyle-changepass input:valid + label{
    left: auto;
      right: 1.5rem;
  }
  .icon-profile span{
    padding-right:1rem
  }
  .seat-guideline:before{margin-left:1rem;margin-right: 0;}
  .PaymentSteps .step li span { left: auto; right:50%;}
  .PaymentSteps li:nth-child(3) span{right:35%}
  .leaflet-container ~ div > div:nth-child(1){order:1}
  .paymentRightSec .totalPrice , .form-group.has-error , .feedback{text-align: right !important;}
  .form-group-newstyle-changepass input:focus + label{left: auto;}
  .auth-form-main{
    & > h2 , & > p.sign-tc{
      text-align:right !important;
    }
  }
  .feedback-danger:before{padding-left: .8rem;padding-right: 0;}
  .userSec .userName:before, .userSec .userEmail:before{left: auto;right:1rem}
  .userSec .userName input, .userSec .userEmail input{padding:1.8rem 4rem 1.8rem 1rem}
  .userSec .userName label, .userSec .userEmail label{left:auto;right:8%}
  .userProfileNav li.active > * {border-left: none;border-right:2px solid var(--text-active)}
  .userProfileNav li > *:before {margin-left: 3rem;}
  .userProfileNav .changePass .changepass-child{margin-right: 6rem;margin-left: 0;}
  .mobileFilter_input__3Hq-J:focus + label {left:auto}
  .mobileFilter_radio__3EJ_l::before{margin-right: 0;margin-left: 1rem;}
  .carousel-sm-hidden .next {transform: rotate(0);}
  .carousel-sm-hidden .prev {left:0}
  .dropdown-list{right:auto;left:10%}
  .CartTicket .chosenseats:hover .seatstooltip {top: -5px;left: 50%;direction: rtl;}
  .rtl-righttext{text-align:right !important;}
  .cong-ul-rtl li:before{margin-right: 0;margin-left:1rem}
  .CartTicket .carousel-wrapper .chosenseats:first-child { margin-right: auto;margin-left:3rem}
  .CartTicket .chosenseats:last-child{margin-right:5rem !important}
  .CartTicket .chosenseats:only-child{margin-right:3rem !important;margin-left: 3 !important;padding:0 2rem;}
  .ticketNav  ul{display: flex;direction: rtl;}
  .userSec .tpSEc .Uemail{text-align: right;}
  .complete-prof .prof-showpass{left: 0; right: 85% !important;}
  .pagination {direction: ltr;}
  .mobile-filter .icon-right-chevron , .dp-prev{transform: rotate(180deg);}
  .concertID > div > div:first-child { margin-right:0 !important;margin-left:-17px !important}
  .profileIconPadding{
    padding-right :3.5rem
  }
  .form-group-newstyle [class*="icon-"]{
    position: absolute;
    top: 18px;
    left:auto;
    right: 12px;
  }
  // .concert .carouselItem{background: linear-gradient(
  //   -211.12deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);}
  .dropdown-item .icon{display: inline-block;transform: translateY(-5px);}
  .concert .Featured .arrow:first-child{margin:0 }
  .concert .Featured .arrow:nth-child(2){margin-left:1rem }
  .retypePass{right:-10% !important}
  .input-padleft-3{padding-left: 1rem !important; }
  .icon-circle-arrow  {display: inline-block;transform: rotate(180deg);}
  .userTicket  .shadowShape{left: 0;top: 9%;}
  .userTicket  .icon-circle-arrow{ transform: rotate(180deg) !important;}
  .imageHolder_artistHolder_artistname{left: auto;right: .5rem;}
  .imageHolder_artistHolder, .HomeCarouselItem .overlay,
  .concert .carouselItem{
    background: linear-gradient(
    140deg, rgba(0, 0, 0, 0) 29.61%, rgba(0, 0, 0, 0.7) 99.56%);
  }
  .userTicket .left-shape::after{right:100%}
  .ticketdetail .seatdetails-row::after, .ticketdetail .seatdetails-section::after{right:100%}
  .ps-fa-4{padding-right: 1.5rem !important;}
  .fortoday {padding: 3rem 3rem 3rem 1rem; }
  .EventDetail .ticketSvg{left: auto;right:42%}
  .form-group-newstyle input[name=name],
  .form-group-newstyle input[name=subject] {
    direction: rtl; text-align: right !important;
  }
  // ==============================================
  @media screen and (min-width: 1400px){
    .PaymentSteps .step li span { left: auto; right:60%;}
    .PaymentSteps li:nth-child(3) span{right:40%}
  }
}
