.aboutus {
  background-color: $white;
  @include media-breakpoint-up("lg") {
    padding: 4.8rem auto;
    margin-bottom: 8rem;
  }
  border-radius: 1rem;
}
.contact-msg{
  &-success{
    background-color: rgba($caribbean-green,.1);
    color: $caribbean-green;
    border-radius: .8rem;
  }
  &-failure{
    background-color: rgba($flame-pea,.2);
    color: $flame-pea;
    border-radius: .8rem;
  }
}
