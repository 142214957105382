.carousel-sm-visible{
    .ticketcarousel-shadow{
        width: 100%;
    }
}
.ticketcarousel-shadow{
    width: 188px;
    @include media-breakpoint-up("lg") {
    filter: drop-shadow(0px 9px 14px #51515121);
    }
     border-radius: 10px;
     overflow: hidden;
    .seat-left{
        font-size: 1.2rem !important;
    }
}

.texticon{
    .icon{
        font-size: 1.4rem;
    }
}

.TicketCarousel {
    background-color: $white;
    @include media-breakpoint-up("lg"){
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
        border-bottom:1.5px dashed var(--text-disabled)
    }
    span{
        font-size: 1.4rem;
      }
    &_remainTime{
        color: $astronaut;
        height: 44px;
    }
    b{
        @include media-breakpoint-down("sm") {
            font-size: 1.6rem;
          }
       }
}
.tdNoEvents{
    height: 80vh;
    color: $white;
    h3{
        opacity: .6;
    }
}
.shape-bottom{
    background-color: $white;
    @include media-breakpoint-up("lg"){
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;
    }
}
.ticketBox {
    .carousel-wrapper{
        padding-left: 1.8rem;
    }
}
.dashed-line{
    height: 0px;
    border-bottom: 1.5px dashed rgba($nobel,.6);
    width: 80%;
    background-color: $white;
    margin: auto;
    transform: translateY(-0.5px);
}
//============= Carousel Navigation
.carousel-sm-hidden{
    max-width: 40%;
    position: relative;
    .icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding:1rem;
        border-radius: 50%;
        border: 1px solid $mine-shaft;
        color: var(--text-color);
        display: inline-block;
        opacity: 0.3;
        font-size: 1rem;
        &.disabled {
            display: none;
        }
        &:not(.disabled):hover{
            opacity: 1;
            cursor: pointer;
            background-color: $white;
        }
    }
    .next{
        right: 0;
    }
    .prev{
        transform:rotate(180deg) translateY(53%);
        left: -8%;
    }
}
.tc-times:not(:last-child){
    @include media-breakpoint-up("lg"){
        &:after{
            content: "";
            width: 1px;
      height: 1.8rem;
      background-color: rgba(0, 0, 0, 0.05);
      margin: 0 1rem;
      display: inline-block;
      transform: translateY(3px);
        }
    }
}
.tc-times{
    white-space: nowrap;
}