footer {
  padding-top: 8rem;
  color: var(--text-inverse);
  background-color: var(--footer-background);
  @include media-breakpoint-down("md"){
    padding-bottom: 10rem;
  }
  .footer-nav {
    margin: 0;
    padding: 0;
    list-style: none;
    *:first-child {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 3rem;
    }
    *:not(:first-child) {
      // cursor: pointer;
      // white-space: nowrap;
      margin-bottom: 1.5rem;
    }
    a:link, a:hover, a:visited {
      font-size: inherit;
      font-weight: inherit;
    }
  }
  .download-app {
    &-title {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 3.4rem;
    }
    &-btn {
      padding: 1.5rem;
      font-size: 2.2rem;
      line-height: 2.6rem;
      border-radius: 1rem;
      align-items: center;
      display: inline-flex;
      color: var(--page-background);
      background-color: var(--background);
    }
  }
  .social-item {
    color: rgb($white, 0.4);
  }
  .copyright {
    padding: 2rem 0;
    position: relative;
    text-align: center;
    color: rgb($white, 0.4);
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1.5px;
      margin: auto;
      position: absolute;
      top: 0; left: 0; right: 0;
      background-color: rgb($white, 0.1);
    }
  }
  .enemad img {
    padding: 1rem 0;
    border-radius: 1rem;
    background-color: $white;
  }
}

.tabmenu{
 position: fixed;
 bottom: -1px;
 left: 0;
background-image: url('/static/images/tabmenu.png');
background-position: center top;
background-size: cover;
width: 100%;
height: 100px;
color: rgba($mine-shaft,.6);
filter: drop-shadow(0 9px 40px #00000030);
z-index: 1000;
.links{
  position: absolute;
  top: 40%;
  width: 100%;
}
.icon{
  border-radius: 50%;
  font-size: 2rem;
}
.concert{
  color: $white;
  background-color: var(--text-active);
  transform: translateY(-15px);
  font-size: 2.2rem;
}
}
