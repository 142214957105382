.btn, a.btn {
  width: auto;
  // min-height: 5.6rem;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding: 1.5rem 3rem;
  color: var(--text-inverse);
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 5px;
  background-position: center;
  background-color: transparent;
  text-align: center;
  margin: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  @include transition(0.2s);
  &:focus { outline: 0; }
  &:disabled, &.disabled {
    opacity: 0.2;
    cursor: default;
  }
  &:not(:disabled, .disabled, .btn-tag) {
    &:focus, &:hover {
      filter: brightness(90%);
    }
    &[loading]:not([loading="false"]) {
      color: transparent;
      background-size: 4rem;
      background-repeat: no-repeat;
      background-color: var(--primary-color);
      background-image: url('/static/images/loading.gif');
    }
  }
  &.btn-tag {
    // cursor: auto;
    min-height: unset;
    font-weight: normal;
    padding: 0.2rem 1rem;
    vertical-align: text-bottom;
  }
  &.btn-small {
    min-height: unset;
    // line-height: 3rem;
    padding: 0.5rem 1rem;
  }
  &.btn-primary {
    background-color: var(--primary-color);
    box-shadow: 0px 4px 6px 0px rgb($flame-pea, 20%);
    &:not(:disabled, .disabled, .btn-tag) {
      &:focus, &:hover {
        box-shadow: 0px 14px 24px 0px rgb($flame-pea, 15%);
      }
    }
  }
  &.btn-secondary {
    color: var(--primary-color);
    border-color: var(--primary-color);
  }
  &.btn-success {
    background-color: var(--success-color);
  }
  &.btn-info {
    background-color: var(--info-color);
  }
  &.btn-warning {
    background-color: var(--warning-color);
  }
  &.btn-danger {
    background-color: var(--danger-color);
  }
  &.btn-disable {
    color: var(--text-color);
    background-color: $silver;
  }
}
