@use "sass:math";

header {
  background-color: var(--background);
  .nav-icon .icon, .icon-menu {
    font-size: 2.2rem;
    vertical-align: middle;
  }
  .nav-support {
    padding: 2rem 0;
    position: relative;
    &::after {
      content: '';
      display: block;
      width: calc(100% - 2rem);
      height: 1px;
      margin: auto;
      position: absolute;
      bottom: 0; left: 0; right: 0;
      background-color: rgb(#000, 0.1);
    }
  }
  .nav-brand {
    margin-inline-end: 3rem;
  }
  .nav-link {
    @include transition(100ms);
    border: 2px solid transparent;
    border-left: none;
    border-right: none;
    &:hover, &.active {
      color: var(--primary-color);
      border-bottom-color: var(--primary-color);
    }
  }
  .profile-photo {
    $width: 5.5rem;
    $border: 2px;
    width: $width;
    height: $width;
    cursor: pointer;
    position: relative;
    border-radius: $width;
    padding: $border * 1.5;
    background-size: cover;
    background-clip: content-box;
    border: $border solid $peach-cream;
    &::after {
      content: '';
      width: math.div($width, 2);
      height: $width;
      position: absolute;
      top: -$border; right: -$border;
      border: $border solid var(--primary-color);
      border-left: none;
      border-top-right-radius: $width;
      border-bottom-right-radius: $width;
    }
  }
  .btn { line-height: 1.6rem; }
}

[icon-badge] {
  position: relative;
  &::after {
    color: $white;
    width: 2.2rem;
    display: block;
    height: 2.2rem;
    top: 0; right: 0;
    font-size: 1.6rem;
    font-weight: bold;
    position: absolute;
    text-align: center;
    font-family: Gilroy;
    line-height: 1.8rem;
    border-radius: 2rem;
    border: 2px solid $white;
    content: attr(icon-badge);
    transform: translate(50%, -60%);
    background-color: var(--primary-color);
  }
}
