.calendar {
  --width: 4rem;
  padding: 3rem;
  padding-top: 0;
  &-wrapper {
    padding: 3rem;
    color: var(--text-color);
    border-radius: $border-radius;
    background-color: var(--background);
    box-shadow: 0px 10px 30px 0px rgb(#000, 0.08);
  }
  &-prev { }
  &-next {
    transform: rotate(180deg);
  }
  &-weekday {
    margin: 2px;
    opacity: 0.4;
    flex: 0 0 auto;
    font-size: 1.2rem;
    text-align: center;
    width: var(--width);
    @include text-truncate();
  }
  &-day {
    margin: 2px;
    opacity: 0.2;
    flex: 0 0 auto;
    display: block;
    cursor: default;
    font-weight: 600;
    color: $astronaut;
    text-align: center;
    border-radius: 5px;
    width: var(--width);
    height: var(--width);
    line-height: var(--width);
    border: 1px solid $astronaut;
    &.available {
      opacity: 1;
      cursor: pointer;
      border-color: rgb($astronaut, 0.1);
    }
    &.soldout {
      opacity: 0.4;
      border: none;
      background-color: $athens-gray;
    }
    &.selected {
      opacity: 1;
      border: none;
      cursor: pointer;
      color: var(--text-inverse);
      background-color: var(--primary-color);
    }
  }
  &-guide {
    display: flex;
    flex: 0 0 auto;
    font-weight: bold;
    font-size: 1.4rem;
    align-items: center;
    &::before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      display: block;
      border-radius: 1.6rem;
      margin-inline-end: 1.5rem;
      border: 1px solid rgb($astronaut, 0.1);
    }
    &.soldout::before {
      background-color: $wild-sand;
    }
    &.selected::before {
      border: none;
      background-color: var(--primary-color);
    }
    &.available::before { }
  }
  @include media-breakpoint-down('lg') {
    --width: 4rem;
    padding: 0;
    padding-bottom: 1.5rem;
    &-wrapper {
      padding: 1.5rem 0;
      box-shadow: none;
    }
    &-guide {
      display: block;
      &::before {
        width: 4rem;
        height: 6px;
        margin-bottom: 5px;
      }
    }
  }
}
