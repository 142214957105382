@media screen and (max-width: 768px) {
  .imageHolder_cover {
    height: auto;
  }
  .remainTime {
    padding-left: 2.4rem;
    padding-bottom: 1.5rem;
  }
  .forTD {
    max-width: 100%;
    // height: 33px;
  }
  .imageHolder {
    height: 134px;
  }
  .xs-white {
    color: $white;
  }
}

@media screen and (min-width: 1100px) {
  .lg-white {
    background-color: $white;
  }
  .upSection{
    // box-shadow: 0 3px 15px rgba(156, 156, 156, 0.5);
    box-shadow: 0px 24px 44px 0px #0000001c;
  }
}
.fortoday{
  box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.25);
border-radius: 10px;
  padding:3rem 1rem 3rem 3rem;
 @include media-breakpoint-down("sm"){
   padding-left: 1rem;
 }
 .arrow{
  --width: 4rem;
  font-size: 1rem;
  --color: #{rgb($mine-shaft, 0.3)};
  @include media-breakpoint-down("sm") {
    --color: #{$white};
  }
 }
 overflow: hidden;
  .carousel-wrapper {
    overflow: visible;
  }
  .carousel-slide {
    border-top: none;
  }
}

.imageHolder {
  position: relative;
  max-width: 100%;
  height: auto;
  max-height: 150px;
  @include media-breakpoint-down("sm"){
    max-height: 14rem;
  }
  border-radius: 10px;
  overflow: hidden;
  background: #2222;
  &_artistHolder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: linear-gradient(
      226.12deg,
      rgba(0, 0, 0, 0) 29.61%,
      rgba(0, 0, 0, 0.7) 99.56%
    );
    border-radius: 10px;
    &_artistname {
      left: 0;
      bottom: 0;
      margin: 0;
      color: $white;
      padding: 1rem;
      font-weight: 700;
      font-size: 2.4rem;
      position: absolute;
    }
  }

  &_cover {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
}
.upSection {
  width: 33rem;
  background-color: $white;
  border-radius: 10px;
  @include media-breakpoint-down("sm"){
    width: 31rem;
  }
}
.downSection-FT {
 // box-shadow: 0px 14px 24px 0px #0000000d;
 filter: drop-shadow(0 14px 24px #51515121);
 background-color: transparent;
}
.EventDate {
  font-size: 1.4rem;
}
.EventDetail {
  position: relative;
  .ticketSvg {
    display: inline;
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
  }
}
.remainTime {
  color: #28526f;
  font-size: 2.4rem;
  font-weight: 700;
  //   padding: 2.8rem 2.4rem 2.7rem 2.4rem;
  padding-top: 2.8rem;
  padding-bottom: 2.7rem;
}
.seatLeft {
  color: $pale-sky;
  font-size: 1.2rem;
}
.seatMap {
  p {
    font-size: 1.4rem;
    font-weight: 500;
  }
}
.price {
  color: $flame-pea;
  span {
    display: block;
    font-size: 1rem;
  }
  strong {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    margin-top: -0.8rem;
  }
}
.bgWhite {
  background-color: $white;
}
.priceHolder {
  border-radius: 10px;
   border-top-right-radius: 15px;
   border-bottom-right-radius:15px;
  position: relative;
  padding-bottom: 1.7rem !important;
  &:after {
    content: "";
    position: absolute;
    top: 52%;
    left: 100%;
    transform: translate(-50%, -50%);
    height: 80%;
    border-right: 2px dashed rgba(0, 0, 0, 0.1);
  }
}
.EventLoc {
  border-radius: 10px;
  border-top-left-radius: 15px;
  border-bottom-left-radius:15px;
  font-weight: 500;
}
.btn {
  &.forTD {
    padding: 0.5rem 3rem;
    font-size: 1.4rem;
  }
}

.forTD {
  /*width: 16.6rem;
  height: 3.3rem;*/
  background-color: $flame-pea;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 3rem;
  font-size: 1.4rem;
}
.carousel-slide{
  .downSection-FT{
    .texticon{
      font-size: 1.3rem;
      [class*="icon-"]{
        color: #23212170;
        font-size: 1.4rem;
      }
    }
  }

}
.HomeCarouselItem{
  position: relative;
  min-height: 220px;
  color: $white;
  width: 32rem;
  @include media-breakpoint-down("sm"){
    max-width: 30rem;
  }
  border-radius: 1rem;
  overflow: hidden;
  box-sizing: border-box;
  img, .overlay {
    position: absolute;
    top: 0; left: 0; right: 0;
  }
  .overlay{
    background: linear-gradient(226.12deg, rgba(0, 0, 0, 0) 29.61%, rgba(0, 0, 0, 0.8) 99.56%);
    height: 100%;
    h3{
      @include media-breakpoint-down("sm"){
        font-size: 2rem;
      }
    }
    .texticon{
      .icon{
        transform: translateY(-3px);
      }
      @include media-breakpoint-down("sm"){
        font-size: 1.4rem;
      }
    }
  }
}
