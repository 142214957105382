.dp {
    $width: 3.2rem;
    &-wrapper {
        display: flex;
        font-weight: 600;
        font-size: 1.4rem;
        position: relative;
        align-items: center;
        &:focus {
            outline: none;
        }
        overflow: visible !important;
        &:not(.open) {
            cursor: pointer;
        }
    }
    &-icon {
        font-size: 1.8rem;
    }
    &-input {
        border: 0;
        flex: 1 1 0;
        max-width: 100%;
        padding: 2px 1rem;
        pointer-events: none;
        box-sizing: border-box;
        @include text-truncate();
        background-color: transparent;
        &:focus,
        &:hover {
            outline: 0;
        }
        @include placeholder-color(rgb($mine-shaft, 0.4));
    }
    &-clear {
        cursor: pointer;
        margin: 0 0.5rem;
        font-size: 2.4rem;
        font-weight: bold;
        color: $astronaut;
    }
    &-arrow {
        font-size: 1rem;
        font-weight: bolder;
        transform: rotate(90deg);
        transition: all 500ms ease;
        &:hover {
            cursor: pointer;
        }
    }
    &-wrapper.open &-arrow {
        transform: rotate(-90deg);
    }
    &-calendar {
        z-index: 1;
        // min-width: 100%;
        padding: 1.5rem;
        margin-top: 4px;
        left: 0;
        top: 100%;
        position: absolute;
        border-radius: 8px;
        background-color: $wild-sand;
    }
    &-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-nav {
        margin-bottom: 1rem;
    }
    &-prev,
    &-next {
        border: 0;
        width: 2rem;
        height: 2rem;
        margin: 0 6px;
        cursor: pointer;
        background-color: $mercury;
    }
    &-next {
        transform: rotate(180deg);
    }
    &-month {
        flex: 0 0 auto;
        cursor: pointer;
        width: 3 * $width;
        text-align: center;
    }
    &-year {
        flex: 0 0 auto;
        cursor: pointer;
        width: 2 * $width;
        text-align: center;
    }
    &-dropdown {
        position: relative;
        &-items {
            z-index: 2;
            display: none;
            width: 100%;
            max-height: 10rem;
            margin-top: 0.5rem;
            position: absolute;
            overflow-x: hidden;
            overflow-y: auto;
            & > * {
                padding: 0.5rem 0;
            }
            background-color: $wild-sand;
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
            box-shadow: 0px 6px 40px 0px rgb(#000, 0.06);
        }
        &::after {
            font-size: 1rem;
            content: "\e93a";
            font-family: ticlix;
            display: inline-block;
            transform: rotate(90deg);
            margin-inline-start: 5px;
        }
        &:focus {
            outline: 0;
            pointer-events: none;
        }
        &:focus &-items {
            display: block;
            pointer-events: auto;
        }
    }
    &-weekday {
        width: $width;
        flex: 0 0 auto;
        text-align: center;
    }
    &-day {
        margin: 2px 0;
        width: $width;
        height: $width;
        flex: 0 0 auto;
        cursor: pointer;
        font-size: 1.2rem;
        text-align: center;
        line-height: $width;
        border-radius: $width;
    }
    &-disable {
        opacity: 0.4;
        cursor: default;
    }
    &-out {
        opacity: 0.2;
    }
    &-today {
        text-decoration: underline;
    }
    &-day:not(&-disable):hover {
        line-height: 3rem;
        border: 1px solid $mandys-pink;
    }
    &-select {
        color: $white;
        background-color: var(--primary-color);
    }
    &-start,
    &-end,
    &-inrange {
        color: $white;
        background-color: $tonys-pink;
    }
    &-start {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    &-end {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    &-inrange {
        border-radius: 0;
    }
    html[lang="fa"] &-start {
        border-radius: $width;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    html[lang="fa"] &-end {
        border-radius: $width;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
