.step{
      list-style-type: none;
      margin: 0;
      padding:0;
      position: relative;
      li{
        position: absolute;
        display: block;
        width: 33%;
        opacity: .2;
        filter: grayscale(1);
        &.active-ps{
            opacity: 1;
            filter: grayscale(0);
        }
        &:after{
            content:"";
            display: inline-block;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border:2px solid #2980b9;
            position: absolute;
            top: 50%;
            left:0;
            transform: translateY(-50%);
            background-color: $white;
            z-index: 1;
        }
        &:before{
          content: "";
          display: inline-block;
          width: 95%;
          height: 2px;
          background-color: #2980b9;
          position: inherit;
          top: 50%;
          left: -95%;
          transform: translateY(-50%);
        }
        span{
            font-weight: 600;
            font-size: 1.4rem;
            color:#28526F;
            font-family: Gilroy;
            position: relative;
            width: 150px;
            top: 40px;
            left: 10%;
            display: block;
            transform: translate(-50%,-50%);
            white-space: nowrap;
        }
        &:first-child{
            left:0;
            &:before{
                display: none;
            }
           span{
               left:18%
           }
        }&:nth-child(2){
            left: calc(33%);    
        }
        &:nth-child(3){
            left: calc(66%);
        }
        &:nth-child(4){
            left: calc(99%);
            span{
                left:23%
            }
        }
     
      }
  }