@use "sass:map";

.dropdown {
  position: relative;
  &-label {
    cursor: pointer;
  }
  &-list {
    right: 0;
    opacity: 0;
    padding: 1rem;
    z-index: 1000;
    margin-top: 4px;
    overflow: hidden;
    min-width: 30rem;
    max-height: 40rem;
    position: absolute;
    visibility: hidden;
    box-shadow: 0px -6px 40px 0px rgb(#000, 0.1);
    border-radius: $border-radius;
    background-color: var(--background);
    max-width: map.get($grid-breakpoints, 'sm');
    @include advance-transition('all 200ms linear, visibility 500ms');
  }
  &-item {
    display: block;
    white-space: nowrap;
    .icon { font-size: 1.8rem; }
    img { vertical-align: text-bottom; }
  }
  &-nav:hover {
    color: var(--primary-color);
  }
  &-separator {
    width: 100%;
    height: 1.5px;
    background-color: rgb($mine-shaft, 0.1);
  }
  &:focus {
    outline: 0;
    pointer-events: none;
  }
  &:focus &-list {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .last-change {
    font-size: 1rem;
    color: rgb($mine-shaft, 0.4);
  }
}
