.userTicket{
    border-radius: 1rem;
    @include media-breakpoint-up("lg"){
        background-color: $white;
        padding: 4.2rem 3.6rem;
    }
    .topSec{
        #userprofile_search{
                background-color: transparent;
                border: 1.5px solid rgba(35, 33, 33, 0.08);
                border-radius: 10px;
                @include media-breakpoint-down("md"){
                    border: 1.5px solid rgba(100, 92, 92, 0.301);
                    // padding-left: 3.5rem;
                    width: 100%;
                    input{background-color: transparent;}
                    .icon{color: $white;}
                }
            input{
            border: none;
                &:focus{
                    outline: none;
                }
                @include media-breakpoint-down("md"){
                    color: $white;
                    width: 100%;
                    &::placeholder{
                        color: $white;
                        opacity: 0.6;
                    }
                }
            }
            .icon{opacity: .5;}
        }
        h3{
            font-size: 2.4rem;
            font-weight: 600;
        }
    }
    .ticketNav{
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        @include media-breakpoint-down("md"){
            overflow-x: scroll;
            border-bottom: 2px solid rgba(255, 255, 255, 0.1);
        }
        ul{
            padding: 0;
            margin: 0;
            list-style-type: none;
            white-space: nowrap;
            @include media-breakpoint-down("md"){
                width: 500px;
                overflow-x: scroll;
            }
            li{
                float: left;
                padding: 1.3rem 0.2rem;
                margin-right: 3rem;
                margin-left: 3rem;
                border-bottom: 2px solid transparent;
                text-transform: capitalize;
                transform: translateY(2px);
                cursor: pointer;
                &.active{
                    border-color: var(--text-active);
                    color: var(--text-active);
                }
                @include media-breakpoint-down("md"){
                    color: $white;
                }
            }
        }
    }
    .texticon{
        .icon{transform: translateY(-2px) !important}
         &-userticket{
             font-size: 1.4rem;
            .icon{
                transform: translateY(0) !important;
            }
         }
         &-userticketdownload{
             color: var(--text-active);
             font-size: 1.4rem;
             .icon{
                 font-size: 1.6rem;
             }
         }
         &-userticketenter {
             .icon{
                 font-size: 2.5rem;
                 display: inline-block;
                 text-align: center;
                 padding-right: 0;
             }
         }
         &-close{
             .icon{font-size: 1rem;opacity: 1;transform: translateY(-2px);}
         }
         @include media-breakpoint-down("sm"){
             font-size: 1.2rem;
         }
        }
}
.ticketInfo{
    color: #28526F;
    padding:2.4rem .5rem 0 .5rem;
    border-radius: 1rem;
    z-index: 2;
    // box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1)4
    filter: drop-shadow( 0px 8px 20px #51515121);
    position: relative;
    border-radius: 10px;
    @include media-breakpoint-up("xl"){
        padding-left: 2.4rem;
    }
    h3{
        &.TicketSinger{
            font-size: 2rem;
            @include media-breakpoint-down("sm"){
                font-size: 1.6rem ;
                line-height: 2rem;
            }
        }
      }
      b{
          &.TicketPrice{
              font-size: 1.6rem;
              line-height: 3.2rem;
              margin-bottom: .5rem;
              @include media-breakpoint-up("lg"){
                font-size: 2.4rem;
            }
              color: var(--text-active);
          }
          &.totalSeats{
              font-size: 1.4rem;
              @include media-breakpoint-down("sm"){
                  font-size: 1.2rem;
              }
          }
      }
  img{
      &.dlTicket{
          transform: translateY(-1rem);
      }
      &.ticket-dash-line{
          height: 75%;
          position: absolute;
          top: 50%;
          left: 71%;
          transform:translate(-50%,-50%) ;
      }
  }
}
.left-shape{
    border-radius: 1rem;
    border-top-right-radius: 1.3rem;
    border-bottom-right-radius: 1.3rem;
    background-color: $white;
    position: relative;
    &::after{
        content:"";
        width: 0;
        height: 85%;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
        border-right:1.5px dashed rgba($flame-pea, .2);
    }
}
.right-shape{
    border-radius: 1rem;
    background-color: $white;
    border-top-left-radius: 1.3rem;
    border-bottom-left-radius: 1.3rem;
}
.shadowShape{
    .row{
        height: 100%;
    }
    filter: drop-shadow( 0px 8px 20px #51515121);
    position: absolute;
    top: 8%;
    left: 3px;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .5;
    transform: scale(.9) ;
    .right-shape , .left-shape{
        height: 100%;
    }
    @include media-breakpoint-up("lg"){
        top: 12%;
        left:15px;
    }
}
//===========================  user ticket styles ======================
.ticketdetail{
   background-color: $white;
   color: $astronaut;
   box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.1);
   border-radius: 1rem;
   padding: 2.4rem 2.8rem 2.4rem 2.4rem;
   @include media-breakpoint-down("sm"){
       padding:1.8rem 1.6rem;
   }
    .texticon{
        font-size: 1.2rem;
        margin: .8rem 0;
        @include media-breakpoint-down("sm"){
          margin:2px 0
        }
        .icon{
            opacity: .4;
        }
        &-userticketdownload{
            margin-top: 0;
            .icon{
                opacity:1
            }
        }
    }
    .seatdetails{
        color: $astronaut;
        background-color: rgba($astronaut, .05);
        border-radius: 1rem;
        span{
            display: block;
            position: relative;
            font-size: 1.4rem;
            width: 100%;
            text-align: center;
        }
        strong{
            display: block;
            font-size: 1.6rem;
            font-weight: 700;
            text-align: center;
            @include media-breakpoint-down("sm"){
                font-size: 1.6rem;
            }
        }
        &-section , &-row{
            &::after{
                content: "";
                display: inline;
                background-color: rgba(#28526F,0.1);
                width: 1px;
                height: 3.2rem;
                position: absolute;
                top: 50%;
                right:0;
                transform: translateY(-50%);
            }
        }
    }
    h3{
      @include media-breakpoint-down("sm"){
     font-size: 1.6rem;
           }
      }
}
.upbreadcrumb{
    border-bottom: 2px solid rgba($shark,0.1);
    @include media-breakpoint-down("sm"){
        border-color: rgba($white,0.1);
    }
    ul{
        margin:0;padding:0;list-style-type: none;
        li{
            float:left;
            padding: 1rem 0;
            &:after{
                content: "/";
                display: inline-block;
                opacity: .5;
            }
            &:last-child,&:first-child{
                &:after{
                    content: "";
                    display: none;
                }
            }
            span{
                color: rgba($shark,0.3);
                font-size: 1.4rem;
                text-transform: capitalize;
                padding:0 .5rem;
                cursor: pointer;
                &.active{
                    color: rgba($shark,1);
                    cursor: initial;
                }
            }
        }
    }
    //=====mobile=====
    .sm-ticket-nav{
        color:$white;
        opacity: .6;
        a{
            img{
                transform: rotate(-90deg) translateY(2px);
                width: 16px;
            }
        }
        h4{
            font-size: 1.6rem;
        }
    }
}
.tickets-actions{
    background-image: url('/static/images/user-tickets.svg');
    background-position: center;
    position: fixed;
    bottom: 75px;
    left: 0;
    width: 100%;
    height: 76px;
    background-size: cover;
    font-size: 1.4rem;
}
// .status{
//     border-radius: 8px;
//     &-success{
//     color: #13be0f;
//     background-color: #13be0f10;
//     }
//     &-pend{
//         color: #c9bd4f;
//         background-color: #ffe60040;
//     }
//     &-cancel{
//         color: $flame-pea;
//         background-color: rgba($flame-pea, 0.1);
//     }
// }
