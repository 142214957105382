.modal {
  &-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    display: block;
    cursor: pointer;
    position: fixed;
    backdrop-filter: blur(2px);
    background-color: rgb(#000, 0.3);
  }
  &-wrapper {
    left: 0;
    right: 0;
    top: 50%;
    height: auto;
    margin: auto;
    display: flex;
    z-index: 1002;
    max-width: 85%;
    max-height: 85%;
    position: fixed;
    overflow: hidden;
    flex-flow: column nowrap;
    transform: translateY(-50%);
    border-radius: $border-radius;
  }
  /* ----- Animate on Opening -----  */
  &.animate &-overlay {
    animation: modal-overlay 0.2s linear;
  }
  &.animate &-wrapper {
    animation: modal-wrapper 0.2s linear;
  }
  /* --- Animate with Transition --- */
  &.pure {
    opacity: 0;
    visibility: hidden;
    @include transition(0.2s);
  }
  &.visible {
    opacity: 1;
    visibility: visible;
  }
  &.pure &-wrapper {
    @include transition(0.2s);
    transform: translateY(-100%);
  }
  &.visible &-wrapper {
    transform: translateY(-50%);
  }
  /* ------------------------------- */
  &-close {
    z-index: 1003;
    cursor: pointer;
    padding: 0 1rem;
    font-size: 3rem;
    line-height: 3rem;
    margin-inline-start: auto;
  }
  &-header,
  &-content,
  &-footer {
    position: relative;
    background-color: $white;
  }
  &-header {
    display: flex;
  }
  &-content {
    margin: -1px 0;
    overflow: auto;
  }
}

@keyframes modal-overlay {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes modal-wrapper {
  from { transform: translateY(-100%); }
  to { transform: translateY(-50%); }
}
