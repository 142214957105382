
.payment-failure{
    margin-top: 1.6rem;
    margin-bottom: 5rem;
    padding-bottom: 5rem;
}
.failure-msg{
    .texticon{
         .icon{
             font-size: 2rem;
             transform: translateY(0px);
             display: inline-block;
         }
     }
}
.err-msg{
 font-size: 2.4rem;        
 color:$mandy !important;
 font-weight: 700;
 line-height: 2.9rem;
}
.errorBox{
    background: rgba(236, 51, 39, 0.05);
    border-radius: 10px;
 p{
    padding:1.6rem 2.4rem;
    color: $astronaut;
     span{
        font-weight: 700;
        color: $mandy;
     }
 }
}
.extra-info{
    color:$astronaut
}
.payment-followUs{
font-weight: 700;
font-size: 2rem;
color: $astronaut;
}
.btn{
    &.backtoconcert{
        width: 100%;
    }
}
//======= MEDIA 
@media screen and (max-width: 768px){
    .err-msg{
        font-size: 2rem;
        line-height: 2.3rem;
        font-weight: 600;
       }
}
@media screen and (min-width: 1200px){
    .phoneNumber , .whatsapp , .email{
        color: $astronaut;
        font-size: 1.4rem;
        position: relative;
        &:before{
            margin-right:14px;       
        }
    }
    .payment-followUs{
        padding-top: 5rem;
    }
}