
$white: #fff;
$peach-cream: #FFEFE1;
$alabaster: #FAFAFA;
$wild-sand: #F5F5F5;
$mandys-pink: #F1B9AF;
$gallery: #EEEEEE;
$mercury: #E9E9E9;
$athens-gray: #EAEAEC;
$tonys-pink: #EB9786;
$iron: #D5D5D8;
$flame-pea: #DC5136;
$nobel: #B6B6B6;
$spun-pearl: #ABABB0;
$manatee: #8F8F95;
$jumbo: #828289;
$tuna: #2F2F3A;
$charade: #2E2E3A;
$astronaut: #28526F;
$mine-shaft: #232121;
$shark: #1F1F27;

$pizazz: #ff8800;
$goldenrod: #fecf6d;
$albescent-white: #f7edd4;
$mandy: #e64c4e;
$geyser: #dbdfe7;
$silver: #c9c9c9;
$pale-sky: #6C7481;
$bismark: #4a6286;
$pickled-bluewood: #2e394c;
$caribbean-green: #00cb94;
