aside {
  $width: 32rem;
  max-width: 0;
  height: 100%;
  z-index: 9999;
  position: fixed;
  top: 0; left: 0;
  overflow: hidden;
  @include transition();
  background-color: var(--background);
  &:focus {
    outline: 0;
    max-width: $width;
  }
  .close {
    float: right;
    line-height: inherit;
  }
  nav {
    width: $width;
    padding: 4rem 2.4rem;
  }
  .nav-brand {
    margin-bottom: 4rem;
  }
  .user-profile img {
    height: 4.8rem;
    width: 4.8rem;
    object-fit: cover;
    border-radius: 4.8rem;
  }
  .nav-icon {
    display: flex;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 4.8rem;
    align-items: center;
    justify-content: center;
    .icon { font-size: 2.2rem; }
    border: 1px solid rgb($mine-shaft, 0.1);
  }
  .nav-link {
    display: flex;
    font-weight: 600;
    .icon {
      font-weight: 600;
      font-size: 1.8rem;
      margin-inline-end: 1.5rem;
    }
  }
  .support-link {
    font-weight: 600;
    color: $astronaut;
    font-size: 1.4rem;
  }
  .social-item {
    font-size: 2rem;
    color: $astronaut;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: rgb(#000, 0.1);
}

.vertical-separator {
  width: 1px;
  height: 80%;
  background-color: rgb(#000, 0.1);
}

///=============== Dashboard Menu
.dashboardMenu {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: -100%;
  padding: 4rem 2.4rem;
  transform-origin: -50%;
  background-color: $white;
  @include transition(300ms);
  a {
    display: block;
    &.active {
      color: var(--text-active) !important;
    }
  }
  .lastchange {
    font-size: 1.2rem;
    padding-left: 4rem;
    .date {
      color: var(--text-disabled);
    }
  }
}
.slideBack {
  .icon {
    display: inline-block;
    transform: rotate(180deg);
  }
}
.slideIn {
  z-index: 2;
  left: 0 !important;
}
