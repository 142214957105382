@import "variables";
@import "colors";
@import "mixins";
@import "fonts";
@import "../../public/fonts/icomoon/ticlix.css";

@import "~bootstrap/dist/css/bootstrap-reboot.min.css";
// @import "~bootstrap/dist/css/bootstrap-grid.min.css";
// @import "~bootstrap/dist/css/bootstrap-grid.rtl.min.css";
// @import "~bootstrap/dist/css/bootstrap.min.css";
// @import "~bootstrap/scss/bootstrap-grid";
// @import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/text-truncate";
@import "~leaflet/dist/leaflet.css";
@import "bootstrap-grid";

@import "~react-toastify/dist/ReactToastify.css";

@import "root";
@import "rules";
@import "components/text";
@import "components/position";
@import "components/table";
@import "components/form";
@import "components/button";
@import "components/modal";
@import "components/checkbox";
@import "components/input-item";
@import "components/pagination";

@import "components/auth";
@import "components/header";
@import "components/dropdown";
@import "components/sidebar";
@import "components/footer";
@import "components/carousel";
@import "components/arrow";
@import "components/calendar";
@import "components/datepicker";
@import "components/nouislider";
@import "components/ticket";

//=======kourosh
@import "components/fortoday";
@import "components/paymentBackSec";
@import "components/paymentSteps";
@import "components/paymentSuccess";
@import "components/paymentFailure";
@import "components/breadcrumb";
@import "components/cart";
@import "components/concert";
@import "components/todaysConcert";
@import "components/userprofile";
@import "components/userticket";
@import "textIcons";
@import "components/concertfilter";
@import "components/changepass";
@import "components/userinvoice";
@import "components/aboutus";

.breadcrumb-temp {
    padding: 3rem;
    color: $manatee;
    font-weight: 500;
    font-size: 1.4rem;
    border-radius: $border-radius;
    background-color: rgb($white, 0.1);
    .active {
        color: $white;
    }
    @include media-breakpoint-down("lg") {
        display: none;
    }
}

main {
    color: var(--background);
    border-radius: $border-radius;
    @include media-breakpoint-up("lg") {
        padding: 3rem;
        color: inherit;
        background-color: var(--background);
        box-shadow: 0px 24px 34px 0px #0004;
    }
}

.leaflet-container {
    background-color: transparent;

    @media (max-width: 1280px) {
        min-height: 35rem !important;
    }
}

.leaflet-tooltip {
    color: $white;
    border: none;
    padding: 1rem;
    border-radius: 1rem;
    $bg: rgb(#000, 0.9);
    background-color: $bg;
    &-left:before {
        border-left-color: $bg;
    }
    &-right:before {
        border-right-color: $bg;
    }
    &-top:before {
        border-top-color: $bg;
    }
    &-bottom:before {
        border-bottom-color: $bg;
    }
    & > * {
        display: flex;
        justify-content: space-between;
    }
}

.leaflet-control.mini-map {
    padding: 7px;
    cursor: pointer;
    background-color: $white;
    .icon-dashboard {
        font-size: 2rem;
    }
    .icon-close {
        font-size: 1.2rem;
        font-weight: bold;
        position: absolute;
        top: 7px;
        left: 7px;
    }
    @include media-breakpoint-down("lg") {
        padding: 0;
    }
    img {
        width: 12rem;
    }
    &:not(.collapse) > *:first-child,
    &.collapse > *:not(:first-child) {
        display: none;
    }
    @include media-breakpoint-down("lg") {
        img {
            width: 9rem;
        }
    }
}

//======aside shadow
.aside-menu {
    box-shadow: 0 3px 20px rgba(56, 56, 56, 0.4);
}
//=======aside shadow

// register/login page
#policy-content,
#policy-content p {
    text-align: justify;
    line-height: 32px;
}
// register/login page

// =======concert id page
@media screen and (max-width: 991px) {
    #select-concert-time-checkbox {
        display: block !important;
        padding-left: 2rem;
        padding-right: 2rem;
    }
    #choose-seat-button-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    #choose-seat-button-container button {
        width: 100% !important;
    }

    #term-parent {
        top: 0;
        transform: unset;
        left: 0;
    }

    #term-wrapper {
        left: 0;
        transform: unset;
        top: 0;
        height: 100vh;
    }

    #term-wrapper .scrollbars {
        max-height: 100vh !important;
    }

    #term-wrapper .scrollbars > div:first-child {
        min-height: 100vh !important;
    }
}
