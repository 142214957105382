@font-face {
  font-family: Roboto;
  src: local('Roboto'), url('/static/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Roboto;
  src: local('Roboto'), url('/static/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilory'), url('/static/fonts/Gilroy/Gilroy-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilory'), url('/static/fonts/Gilroy/Gilroy-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: IRANSans ;
  src: local('iransans'), url('/static/fonts/iransans/IRANSansWeb(FaNum)_Light.woff') format('opentype');
  font-weight: normal;
  font-style: normal;
}
