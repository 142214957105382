.userProfileNav {
    background-color: $white;
    border-radius: 1rem;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        padding-inline-start: 1.4rem;
        padding-inline-end: 0.5rem;
    }
    li {
        display: block;
        padding: 1.5rem 0.5rem;
        @include media-breakpoint-up("xl") {
            padding: 2rem 0rem;
            cursor: pointer;
        }
        text-transform: capitalize;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        &:last-child {
            border: none;
            margin-bottom: 0;
        }
        &.active {
            & > * {
                color: #dc5136;
                border-left: 2px solid #dc5136;
            }
        }
        & > * {
            padding-left: 1rem;
            display: block;

            @include media-breakpoint-up("xl") {
                padding-left: 2rem;
            }
            &:before {
                display: inline-block;
                margin-right: 2rem;
                font-family: "ticlix";
                transform: translateY(2px);
                font-size: 1.8rem;

                @include media-breakpoint-up("xl") {
                    margin-right: 3rem;
                }
            }
        }
    }
    .profile {
        &:before {
            content: "\e914";
        }
    }
    .myTicket {
        &:before {
            content: "\e91a";
        }
    }
    .accounting {
        &:before {
            content: "\e920";
        }
    }
    .changePass {
        .changepass-child {
            font-size: 1rem;
            display: block;
            margin-left: 4rem;
            margin-top: -4px;
            @include media-breakpoint-up("xl") {
                margin-left: 4.9rem;
            }
        }
        &:before {
            content: "";
            transform: translateY(5px);
        }
    }
}
//=======end of nav
.userSec {
    background-color: $white;
    @include media-breakpoint-up("lg") {
        padding: 4.8rem auto;
    }
    border-radius: 1rem;
    .tpSEc {
        // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        & > p {
            display: inline-block;
        }
        .Uname {
            @include media-breakpoint-down("sm") {
                font-size: 2rem;
                color: var(--text-active);
            }
        }
    }
    .profileprogress {
        display: block;
        width: 90px;
        height: 90px;
        position: relative;
        &:after {
            content: "";
            width: 50px;
            height: 100px;
            position: absolute;
            top: 50%;
            right: -5px;
            border: 2px solid var(--primary-color);
            border-left: none;
            border-top-right-radius: 5.5rem;
            border-bottom-right-radius: 5.5rem;
            transform: translateY(-50%);
        }
    }
    .profile-picture {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5.5rem;
    }
    .profilePic-wrapper {
        border-radius: 50%;
        overflow: hidden;
        height: 100%;
        width: 100%;
        img {
            object-fit: cover;
        }
    }
    .profilePic-wrapper-mobile {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        overflow: hidden;
    }
    .btSEc {
        .myprofile-desktop {
            .texticon {
                .icon {
                    font-size: 2.5rem;
                    color: $flame-pea;
                    transform: translateY(-2px);
                    font-weight: 500;
                }
            }
        }
    }
    .profilePic-wrapper {
        height: 120px;
        width: 120px;
    }
    .upload-pic {
        color: var(--text-active);
        transform: translateY(-40px);
        background-color: $white;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
        &:before {
            content: "\e937";
            font-family: "ticlix";
            display: inline-block;
            margin-right: 1.7rem;
            font-size: 1.6rem;
        }
    }
    .clearfield {
        position: absolute;
        top: 50%;
        right: 1.7rem;
        transform: translateY(-50%);
        font-size: 1rem;
    }
    .userName,
    .userEmail {
        position: relative;
        @include media-breakpoint-down("md") {
            margin-bottom: 2rem;
            text-align: center;
        }
        &:before {
            content: "\e914";
            font-size: 1.8rem;
            font-family: "ticlix";
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translate(-50%, -50%);
        }
        label {
            display: inline;
            font-size: 1.2rem;
            background: transparent;
            color: #23212194;
            position: absolute;
            pointer-events: none;
            top: 0;
            left: 18%;
            background-color: $white;
            transform: translate(-50%, -50%);
            padding: 0.5rem;
            //transition:background-color 200ms , top 500ms ,left 500ms;
        }
        input {
            border: 1.5px solid #eaeaeb;
            background-color: transparent;
            border-radius: 8px;
            padding: 1.8rem 1rem;
            padding-left: 3.5rem;
            width: 100%;
            background: transparent;
            border: 1.5px solid #eaeaeb;
            &:focus,
            &.active {
                outline: none;
                background: transparent;
                //  &+label{
                //    top:0;
                //    left:18%;
                //   background: $white;
                //  }
            }
        }
        img {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            width: 0.8rem;
            transform: translateY(-50%);
        }
    }
    .DateofBirth {
        position: relative;
        border-radius: 8px;
        padding: 1.2rem 1.5rem;
        padding-inline-start: 1rem;
        border: 1.5px solid #eaeaeb;
        input {
            font-size: 1.6rem;
        }
    }
    .selectGender {
        height: 65px;
        padding: 2rem 1.5rem;
        color: $nobel;
        width: 100%;
        position: relative;
        border: 1.5px solid #eaeaeb;
        border-radius: 8px;
        .icon {
            font-size: 1.8rem;
        }
        .icon-right-chevron {
            transform: rotate(90deg);
            font-size: 1rem;
            font-weight: 300;
            font-weight: bolder;
            color: var(--text-color);
        }
        .options {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            background-color: $white;
            border-radius: inherit;
            transition: all 200ms;
            box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
            opacity: 0;
            visibility: hidden;
            z-index: -1;
        }
        &:focus {
            outline: none;
            pointer-events: none;
            .options {
                pointer-events: auto;
                opacity: 1;
                visibility: visible;
                top: 110%;
                z-index: 3;
            }
        }
        .text-color {
            color: var(--text-color) !important;
        }
        .option {
            padding: 0.8rem 2rem;
            cursor: pointer;
            color: rgba($mine-shaft, 0.5);
            &:hover {
                background-color: rgba($nobel, 0.1);
                color: rgba($mine-shaft, 0.8);
            }
        }
    }

    .userEmail {
        @include media-breakpoint-down("md") {
            margin-top: 1.5rem;
        }
        border: 1.5px solid #eaeaeb;
        padding: 0.5rem;
        border-radius: 1rem;
        input {
            color: #23212194;
            border: none;
        }
        &:before {
            content: "\e925";
            font-size: 1.8rem;
            font-family: "ticlix";
        }
    }
    h3 {
        &.profemail,
        &.profmobile {
            margin-top: 5rem;
        }
    }
    .Usermobile {
        &:before {
            content: "";
        }
        input {
            padding-left: 1.7rem;
        }
    }
    .area-code {
        padding: 1.4rem 1.6rem;
        background-color: #eeeeee;
        border-radius: 1rem;
        margin-right: 1rem;
        &:before {
            content: url("/static/images/icons/iranMap.svg");
            display: inline-block;
            transform: translateY(3px);
            margin-right: 0.6rem;
        }
        @include media-breakpoint-down("sm") {
            transform: translateY(-8px);
        }
    }
    .genderMobileview {
        margin-top: 5rem;
        select {
            background-color: #eeeeee;
            &:first-child {
                color: #b6b6b6;
            }
        }
    }
}
.userEmail {
    max-height: 6.5rem;
    input {
        //padding:1.4rem 1rem 1.4rem 3rem !important
    }
}
.userEmail {
    input {
        padding: 1.6rem 1rem;
        padding-left: 3rem;
    }
}
.user-imgWrap {
    min-width: 20vw;
    position: relative;
}
.remove-userpic {
    position: absolute;
    top: -1rem;
    left: 60%;
    cursor: pointer;
    display: inline-block;

    z-index: 3;
    .icon-close {
        padding: 1rem;
        border-radius: 50%;
        background-color: $white;
        color: var(--text-active);
        display: inline-block;
        filter: drop-shadow(0 0 13px #10101020);
    }
}

.iban-input {
    border-radius: 8px;
    border: 1px solid #eaeaeb;
    input {
        padding: 1.1rem 1.5rem;
    }
}
