.form-control {
    width: 100%;
    appearance: none;
    font-size: inherit;
    min-height: 4.8rem;
    border-radius: 8px;
    padding: 5px 1.5rem;
    line-height: 3.2rem;
    @include transition();
    color: var(--text-color);
    border: 1px solid $iron;
    background-color: var(--background);
    @include placeholder-color(rgb($mine-shaft, 0.4));
    -moz-appearance: none;
    -webkit-appearance: none;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &:not(textarea) {
        @include text-truncate();
    }
    &:not(:disabled):not(.disabled):not(.input-group-control),
    &:not(:disabled):not(.disabled):not(.input-group-control) {
        &:hover {
        }
        &:focus {
            outline: 0;
            border-color: $wild-sand;
            background-color: $wild-sand;
        }
        &:focus ~ .feedback.focus {
            display: block;
        }
    }
    &:disabled,
    &.disabled {
        opacity: 0.4;
    }
    @include unify-parent("select") {
        &:enabled {
            cursor: pointer;
        }
        &::-ms-expand {
            display: none;
        }
        &:-moz-focusring {
            transition: none;
            color: transparent;
            text-shadow: 0 0 0 var(--text-primary);
        }
    }
    @include unify-parent("textarea") {
        display: block;
        resize: vertical;
        transition: none;
        line-height: 2.4rem;
        padding: 1rem 1.5rem;
    }
    @include unify-parent('input[type="file"]') {
        padding: 0;
        cursor: pointer;
        &.embed {
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            box-sizing: border-box;
        }
    }
}

.input-group {
    width: 100%;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: center;
    @include transition();
    border-radius: $border-radius;
    background-color: var(--background);
    border: 1px solid var(--input-border);
    .form-control,
    .input-group-addon {
        border: 0;
        color: inherit;
        border-radius: 0;
        background-color: transparent;
    }
    .form-control {
        flex-grow: 1;
        min-height: unset;
    }
    .input-group-addon {
        padding: 0 1.5rem;
        font-weight: bold;
        text-align: center;
        white-space: nowrap;
        align-items: center;
        &:focus {
            outline: 0;
        }
    }
    // .input-group-control { }
    // .input-group-btn { }
    &.disabled {
        color: var(--text-disable);
        background-color: var(--disable-color);
    }
}

.form-group {
    // margin-bottom: 3rem;
    // th &, td & {
    //   margin-bottom: 2px;
    // }
    &.has-error {
        .form-control:not(.input-group-control),
        .input-group,
        tag-input,
        select-input,
        select option {
            border-color: var(--danger-color);
        }
        .feedback,
        [class*="form-label"] {
            color: var(--danger-color);
        }
        textarea {
            border-color: var(--danger-color);
        }
    }
    &-newstyle {
        position: relative;
        margin-bottom: 2rem;
        // min-height: 65px;
        @include media-breakpoint-down("sm") {
            margin-bottom: 3rem !important;
        }
        .icon-profile {
            position: absolute;
            top: 15px;
            left: 12px;
        }
    }
    &-reg-sm {
        min-height: 30px;
        .lr-label-placeholder {
            top: -12% !important;
            line-height: 10px;
            padding: 0 2px;
        }
    }
    textarea {
        border: 1px solid #d5d5d8;
        border-radius: 0.8rem;
        &:focus {
            border-color: rgba($mine-shaft, 0.5);
            outline: none;
        }
    }
}

.feedback {
    width: 100%;
    display: block;
    margin-top: 0.5rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    &-ns {
        // position: absolute;
        // top: 90%;
        // left: 0;
        // display: inline-block;
        // transform: translateY(-50%);
        margin: 10px 0;
    }
    &-danger {
        &:before {
            content: "\e90e";
            font-family: "ticlix";
            font-size: 1.4rem;
            padding-right: 8px;
            display: inline-block;
        }
    }
    &.focus {
        display: none;
    }
}

[class*="form-label"] {
    padding-bottom: 1rem;
    margin: 0;
}
.form-label {
    &-inline {
        line-height: 1.6rem;
        padding: 0;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
    }
    &-edge {
        padding: 0 1rem;
        margin: 0 1rem;
        transform: translateY(11px);
        background-color: var(--background);
    }
}

.form-control-ns {
    // position: absolute;
    display: block;
    padding: 9px 15px;
    &:hover {
        border-color: rgba($mine-shaft, 0.2);
    }
    &:focus,
    &:valid,
    &.dirty {
        outline: none;
        background: $white !important;
        & + label {
            top: -10%;
            left: 2rem;
            background-color: $white !important;
            font-size: 1.2rem;
        }
    }
    &:valid {
        background-color: $white !important;
    }
    &:focus,
    &:valid,
    &:disabled {
        border-color: rgba($mine-shaft, 0.2);
    }
}
//================= label new style ===========
.lr-label-placeholder {
    left: 10%;
    top: 1.8rem;
    display: block;
    font-size: 12px;
    line-height: 1rem;
    font-size: 1.6rem;
    position: absolute;
    pointer-events: none;
    background: transparent;
    padding: 2px 2px 0 2px;
    transition: background-color 10ms, top 500ms, left 500ms, font-size 500ms;
}
.tp {
    top: 17px;
}
//========================
.reg-sm-select {
    min-height: 45px;
}
//==========
input:-internal-autofill-selected,
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
}
.profileIconPadding {
    padding-left: 3.5rem;
}
//========
.tx-checkbox {
    opacity: 0;
    display: none;
    & + label:before {
        content: "";
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1.6rem;
        line-height: 1.7rem;
        border-radius: 0.4rem;
        font-family: "ticlix";
        display: inline-block;
        background-color: transparent;
        transform: translate(0px, 2px);
        border: 1px solid rgba($mine-shaft, 0.2);
    }
    &:checked + label:before {
        content: "\e919";
        border-color: transparent;
        color: var(--text-active);
        transform: translate(0px, 2px) !important;
    }
}
