.concert{
    border-radius: 10px;
    .Featured{
        @include media-breakpoint-up("lg") {
           background-color: $white;
          }
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        h3{
            color:$white;
            @include media-breakpoint-up("lg") {
                color:var(--text-color);
            }
        }
    }
    .carouselBox{
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @include media-breakpoint-up("lg") {
          background-color: $white;
          box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.25);
      }
    }
    .CarouselCoverHolder{
      width: 287px;
      height: 100%;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      figure {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
}
.carouselItem{
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    background: linear-gradient(226.12deg, rgba(0, 0, 0, 0) 29.61%, rgba(0, 0, 0, 0.7) 99.56%);
    color: $white;
    .concertDetail{
      .country{
       font-size: 1.2rem;
       @include media-breakpoint-down("md") {
           color: rgba($color:$white, $alpha: 0.6);
         }
       &:before{
        content:"";
        width: 24px;
         display: inline-block;
     }
    }
   }
  }
  .ArtistTicket {
    figure { height: 15rem; }
  }
}
.ticketBox{
    border-radius: 1rem;
    background-color: $white;
    box-shadow: 0px 24px 34px rgba(0, 0, 0, 0.25);
}

.ArtistTicket {
    figure {
      img {
          border-radius: 1rem;
          object-fit: cover;
      }
    }
   .texticon{
      font-size: 1.4rem;
      color: $tuna;
      @include media-breakpoint-down("sm"){
          font-size: 1.2rem;
      }
      .icon{
          color: $nobel ;
      }
      .concert-country{
          color:$manatee;
      }
   }
   .country{
    font-size: 1.2rem;
    opacity: 0.6;
    &:before{
        content:"";
        width: 24px;
        display: inline-block;
    }
   }
}
//==========================
.concert-id-genre{
    text-transform: capitalize;
    a {
        @include media-breakpoint-down("sm"){
            color:$white !important;
        }
    }
}
.colorGray-sm{
    @include media-breakpoint-down("sm"){
        color:rgba($white, 0.4) !important;
    }
}
.horz-line{
    height: 1.5px;
    background-color:rgba($white,0.1); ;
}
.seat-guideline{
    font-size: 1.4rem;
    display: inline-block;
    &:before{
        content: "";
        display: inline-block;
        margin-right: 1rem;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 50%;
        transform: translateY(3px);
        background-color: var(--color);
    }
}
.arrow-r{
    display: inline-block;
    font-size: 1rem !important;
    color: var(--text-color) !important;
    transform: translateY(-2px);
}
a:last-child .genre-sep {
    display: none;
}

.timeSep{
  width: 1px;
  height: 1.8rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.btn.btn-soldout{
  color: $astronaut;
  padding: 2rem 3rem 0 3rem;
}
.soldout{
    img{
        filter: grayscale(100%);
    }
    &-banner{
        background-color: var(--text-active);
        color: $white;
        position: absolute;
        width:100%;
        bottom:0;
        padding: .5rem 0;
        font-size: 1.4rem;
        border-radius:0 0 .8rem .8rem ;
      }
}
 .overflow-hidden{
     overflow:hidden;
 }
