
.pagination {
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 500;
  // &-text { }
  button {
    margin: 2px;
    width: 4rem;
    height: 4rem;
    border: none;
    border-radius: 4rem;
    color: rgb($white, 0.4);
    background-color: transparent;
    &:focus { outline: 0; }
    &:disabled { }
  }
  & &-page { }
  & &-current {
    color: $white;
  }
  & &-prev,
  & &-next {
    font-size: 1.4rem;
    border: 1px solid rgb($white, 0.1);
    &:not(:disabled) { color: $white; }
  }
  & &-prev {
    transform: rotateY(180deg);
  }
  & &-first { }
  & &-last { }
}
